import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-menu-panel-header',
    templateUrl: './menu-panel-header.component.html',
    styleUrls: ['./menu-panel-header.component.scss']
})
export class MenuPanelHeaderComponent {
    @Input()
    public badge?: string;
}
