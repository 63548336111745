import { Compiler, ComponentRef, Injectable, NgModule, NgModuleFactory, Type, ViewContainerRef } from '@angular/core';

export type LazyModule = Type<NgModule> & { entry: Type<unknown>; };
export type LazyModuleLoader = () => Promise<LazyModule>;

@Injectable()
export class LazyLoaderService {
    constructor(private compiler: Compiler) { }

    public async load(loader: LazyModuleLoader, container: ViewContainerRef): Promise<ComponentRef<unknown>> {
        const tempModule = await loader();

        let moduleFactory;

        if (tempModule instanceof NgModuleFactory) {
            // For AOT
            moduleFactory = tempModule;
        } else {
            // For JIT
            moduleFactory = await this.compiler.compileModuleAsync(tempModule);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const entryComponent = (moduleFactory.moduleType as any).entry;

        container.clear();

        return container.createComponent(entryComponent);
    }
}
