import dayjs from 'dayjs';
import { PeriodPreset, PeriodPresetEnum, periodPresets } from './period-picker.interface';

export const parsePreset = (start: Date, end: Date): PeriodPreset | null => {
    if (!dayjs(end).isSame(dayjs(), 'day')) {
        return null;
    }

    const diffInDays = dayjs(end).diff(start, 'days');

    if (diffInDays === 1) {
        return dayjs(end).diff(start, 'hours') === 24
            ? periodPresets.find(period => period.name === PeriodPresetEnum.LAST_24_HOURS) || null
            : null;
    }

    const threeMonthsEarlier = dayjs(end).subtract(3, 'months');

    if (threeMonthsEarlier.isSame(start, 'day')) {
        return periodPresets.find(period => period.name === PeriodPresetEnum.LAST_3_MONTHS) || null;
    }

    return periodPresets.find(period => period.diff === diffInDays) || null;
};
