import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    template: ''

})
export class LoginComponent implements OnInit {
    constructor(
        private readonly router: Router
    ) {}

    public ngOnInit(): void {
        window.location.href = `/user-session-proxy/authenticate${
            this.router.routerState.snapshot.root.queryParams.redirect_uri
                ? `?service_uri=${this.router.routerState.snapshot.root.queryParams.redirect_uri}`
                : ''
        }`;
    }
}
