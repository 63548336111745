import { Injectable } from '@angular/core';
import { MePermissionInterface } from './models/me.model';
import { catchError, map, Observable, of } from 'rxjs';
import getPermissionsGqlQuery from '@core/me/graphql/queries/get-permissions.query.graphql';
import { Endpoint } from '@core/graphql/models/enums/endpoint.enum';
import { GraphqlService } from '@core/graphql/graphql.service';

@Injectable({
    providedIn: 'root'
})
export class MeService {
    constructor(
        private readonly graphqlService: GraphqlService
    ) {
    }

    public getPermissions(): Observable<MePermissionInterface> {
        const query = getPermissionsGqlQuery;

        return this.graphqlService.query<{
            me: { permission: MePermissionInterface; };
        }>(Endpoint.GatewayApi, query).pipe(
            catchError((err) => {
                if (err.message === 'Forbidden') {
                    return of(null);
                }

                throw err;
            }),
            map(res => res?.me?.permission ?? {
                isCustomerServiceAdministrator: false,
                isCustomerServiceOperator: false
            })
        );
    }
}
