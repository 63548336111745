import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogParams } from './confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent, InfoDialogParams } from './info-dialog/info-dialog.component';
import { SelectDialogComponent, SelectDialogParams } from './select-dialog/select-dialog.component';
import { CopyDialogComponent, CopyDialogParams } from '@shared/interactions/dialog/copy-dialog/copy-dialog.component';
import { FullScreenDialogComponent, FullScreenDialogParams } from '@shared/interactions/dialog/full-screen-dialog/full-screen-dialog.component';

@Injectable()
export class DialogService {
    constructor(private dialog: MatDialog) {
    }

    public openConfirmDialog(params: ConfirmDialogParams): MatDialogRef<ConfirmDialogComponent, boolean> {
        return this.dialog.open(ConfirmDialogComponent, {
            width: `${params.width || 400}px`,
            data: params,
            autoFocus: false
        });
    }

    public openInfoDialog(params: InfoDialogParams): MatDialogRef<InfoDialogComponent, void> {
        return this.dialog.open(InfoDialogComponent, {
            width: `${params.width || 400}px`,
            data: params,
            autoFocus: false
        });
    }

    public openSelectDialog(params: SelectDialogParams): MatDialogRef<SelectDialogComponent, void> {
        return this.dialog.open(SelectDialogComponent, {
            width: `${params.width || 400}px`,
            data: params,
            autoFocus: false
        });
    }

    public openCopyDialog(params: CopyDialogParams): MatDialogRef<CopyDialogComponent, void> {
        return this.dialog.open(CopyDialogComponent, {
            width: `${params.width || 400}px`,
            data: params,
            autoFocus: false
        });
    }

    public openFullScreenDialog(params: FullScreenDialogParams): MatDialogRef<FullScreenDialogComponent, void> {
        return this.dialog.open(FullScreenDialogComponent, {
            height: 'calc(100% - 60px)',
            width: 'calc(100% - 60px)',
            maxWidth: '100%',
            maxHeight: '100%',
            data: params,
            autoFocus: false
        });
    }
}
