import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HasContractorGuard } from '@guards/has-contractor/has-contractor.guard';
import { HasForcedContextGuard } from '@guards/has-forced-context/has-forced-context.guard';
import { InvitationComponent } from './features/invitation/invitation.component';
import { LoginComponent } from './features/login/login.component';
import { LogoutComponent } from './features/login/logout.component';
import { IsAuthorizedGuard } from '@guards/is-authorized/is-authorized.guard';
import { PanelContext } from '@core/context/panel-context.service';

const routes: Routes = [
    {
        path: 'wizard',
        data: {
            context: PanelContext.Wizard
        },
        canActivate: [IsAuthorizedGuard],
        loadChildren: () => import('./wizard/wizard.module').then(x => x.WizardModule)
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'invitation',
        component: InvitationComponent,
        canActivate: [IsAuthorizedGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: '',
        canActivate: [IsAuthorizedGuard, HasForcedContextGuard, HasContractorGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./panel/panel.module').then(x => x.PanelModule)
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
