export enum RoleType {
    CONTRACTOR_ADMIN = 'contractorAdmin',
    CONTRACTOR_OWNER = 'contractorOwner',
    WEBSITE_ANALYST = 'websiteAnalyst',
    WEBSITE_OPERATOR = 'websiteOperator'
}

export enum RoleTypeEntity {
    CONTRACTOR_ADMIN = 'contractor-admin',
    CONTRACTOR_OWNER = 'contractor-owner',
    WEBSITE_ANALYST = 'website-analyst',
    WEBSITE_OPERATOR = 'website-operator'
}

export interface PermissionInterface {
    id: number;
    account: {
        id: number;
        email: string;
    };
    resourceId: string;
    resourceType: string;
}
