import { HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/pl';
import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AsyncJobsModule } from '@core/async-jobs/async-jobs.module';
import { AsyncJobsFetcherServiceClientApi } from '@core/async-jobs/connectors/async-jobs-fetcher-client-api.service';
import { AsyncJobsFetcherService } from '@core/async-jobs/connectors/async-jobs-fetcher.service';
import { InteractionsModule } from '@shared/interactions/interactions.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LazyLoaderService } from './core/lazy';
import { InvitationComponent } from './features/invitation/invitation.component';
import { LoginComponent } from './features/login/login.component';
import { LogoutComponent } from './features/login/logout.component';
import { BreadcrumbsModule } from '@shared/breadcrumbs/breadcrumbs.module';
import * as Sentry from '@sentry/angular';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { PanelContext, PanelContextService } from '@core/context/panel-context.service';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        InvitationComponent
    ],
    imports: [
        BrowserModule,
        BreadcrumbsModule,
        BrowserAnimationsModule,
        InteractionsModule,
        AppRoutingModule,
        HttpClientModule,
        AsyncJobsModule.forRoot({
            providers: [
                { provide: AsyncJobsFetcherService, useClass: AsyncJobsFetcherServiceClientApi }
            ]
        }),
        AngularSvgIconModule.forRoot()
    ],
    bootstrap: [AppComponent],
    providers: [
        LazyLoaderService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {
                appearance: 'fill',
                subscriptSizing: 'dynamic',
                color: 'accent'
            }
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                verticalPosition: 'bottom',
                horizontalPosition: 'right'
            }
        },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'PLN' },
        { provide: LOCALE_ID, useValue: 'pl-PL' },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        }
    ]
})
export class AppModule {
    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly panelContext: PanelContextService
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            const contexts = this.getContexts(this.route.snapshot);

            if (contexts.length) {
                this.panelContext.setContext(contexts[contexts.length - 1]);
            }
        });
    }

    private getContexts(route: ActivatedRouteSnapshot): PanelContext[] {
        const contexts = [];

        if (route.data.context !== undefined) {
            contexts.push(route.data.context);
        }

        for (const child of route.children) {
            contexts.push(...this.getContexts(child));
        }

        return contexts;
    }
}
