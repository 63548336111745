import { ModuleWithProviders, NgModule } from '@angular/core';
import { BreadcrumbsService } from './breadcrumbs.service';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        RouterModule,
        CommonModule
    ],
    exports: [BreadcrumbsComponent],
    declarations: [BreadcrumbsComponent]
})
export class BreadcrumbsModule {
    public static forRoot(): ModuleWithProviders<BreadcrumbsModule> {
        return {
            ngModule: BreadcrumbsModule
        };
    }

    // There we inject service to initialize it
    constructor(
        protected readonly breadcrumbsService: BreadcrumbsService
    ) {}
}
