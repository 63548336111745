export enum PeriodPresetEnum {
    LAST_24_HOURS = 'LAST_24_HOURS',
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_30_DAYS = 'last_30_days',
    LAST_3_MONTHS = 'LAST_3_MONTHS'
}

export interface PeriodPreset {
    label: string;
    name: PeriodPresetEnum;
    diff: number;
}

export const periodPresets: PeriodPreset[] = [
    {
        label: 'Ostatnie 3 miesiące',
        name: PeriodPresetEnum.LAST_3_MONTHS,
        diff: 90
    },
    {
        label: 'Ostatnie 30 dni',
        name: PeriodPresetEnum.LAST_30_DAYS,
        diff: 30
    },
    {
        label: 'Ostatnie 7 dni',
        name: PeriodPresetEnum.LAST_7_DAYS,
        diff: 6
    },
    {
        label: 'Ostatnie 24 godziny',
        name: PeriodPresetEnum.LAST_24_HOURS,
        diff: 1
    }
];
