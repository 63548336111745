import { AfterViewInit, Directive, ElementRef, ViewContainerRef } from '@angular/core';
import { CopyableComponent } from './copyable.component';

@Directive({
    selector: '[copyable]'
})
export class CopyableDirective implements AfterViewInit {
    constructor(
        private element: ElementRef,
        private viewContainerRef: ViewContainerRef) {
    }

    public ngAfterViewInit(): void {
        this.renderHelp(this.element.nativeElement.innerHTML);
    }

    private renderHelp(text: string): void {
        if (!text) {
            return;
        }

        const componentRef = this.viewContainerRef.createComponent(CopyableComponent);
        componentRef.instance.content = text;
        const host = this.element.nativeElement;
        host.style.display = 'flex';
        host.style['align-items'] = 'center';
        host.appendChild(componentRef.location.nativeElement);
    }
}
