<div class="full-screen-dialog">
    <div class="header">
        <button
            class="close-button"
            mat-icon-button
            mat-dialog-close
        >
            <mat-icon
                class="close-button"
                id="close-icon"
            >close</mat-icon>
        </button>
    </div>

    <div class="container">
        <ng-container #target></ng-container>
        <ng-template #loader>
            <app-loader />
        </ng-template>
    </div>

</div>
