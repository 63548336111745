import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logout',
    template: ''

})
export class LogoutComponent implements OnInit {
    public ngOnInit(): void {
        window.location.href = '/user-session-proxy/logout';
    }
}
