import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AttachmentComponent } from './attachment/attachment.component';
import { BadgeComponent } from './badge/badge.component';
import { GuidePanelComponent } from './guide-panel/guide-panel.component';
import { InfoBoxComponent } from './services/info-box/info-box.component';
import { StatusComponent } from './status/status.component';
import { RouterModule } from '@angular/router';
import { SimpleChartComponent } from '@shared/ui/simple-chart/simple-chart.component';

const components = [
    AttachmentComponent,
    BadgeComponent,
    GuidePanelComponent,
    InfoBoxComponent,
    StatusComponent,
    SimpleChartComponent
];
const modules = [
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    ScrollingModule,
    MatSidenavModule,
    MatCardModule,
    RouterModule
];

@NgModule({
    declarations: [
        ...components
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        ...modules
    ],
    exports: [
        ...modules,
        ...components
    ]
})
export class UiModule { }
