<ng-container [ngSwitch]="serviceType">
    <ng-container *ngSwitchCase="ServiceType.VIDEO_ADS">
        Oświadczam, że zapoznałem/am się i akceptuję
        <a
            href="https://panel.onetads.pl/static/video-ads/regulamin.pdf"
            target="_blank"
        >Regulamin Usługi Reklama Wideo</a>.
    </ng-container>
    <ng-container *ngSwitchCase="ServiceType.RETAIL_MEDIA">
        Oświadczam, że zapoznałem/am się i akceptuję
        <a
            href="https://panel.onetads.pl/static/rmn/regulamin-retail-media-network-dla-reklamodawcow.pdf"
            target="_blank"
        >Regulamin Retail Media Network dla reklamodawców</a>.
    </ng-container>
    <ng-container *ngSwitchCase="ServiceType.SKAPIEC">
        Oświadczam, że zapoznałem/am się i akceptuję <a
        [href]="SKAPIEC_AGREEMENT_URL"
        target="_blank"
    >Regulamin Współpracy z Serwisem Skąpiec</a> oraz pełną treść Cennika.
    </ng-container>
    <ng-container *ngSwitchCase="ServiceType.LAMODA">
        Oświadczam, że zapoznałem/am się i akceptuję <a
        href="https://panel.onetads.pl/static/lamoda/regulamin.pdf"
        target="_blank"
    >Regulamin Współpracy z Serwisem LaModa</a>.
    </ng-container>
    <ng-container *ngSwitchDefault>Akceptuję regulamin</ng-container>
</ng-container>
