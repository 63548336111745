import { FormControl } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'input-datetime',
    styleUrls: ['./input-datetime.component.scss'],
    templateUrl: './input-datetime.component.html'
})
export class InputDatetimeComponent {
    @Input()
    public min: Date | null = null;

    @Input()
    public max: Date | null = null;

    @Input()
    public label: string | null = null;

    @Input()
    public form: FormControl = new FormControl();
}
