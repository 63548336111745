import { Component, Inject, inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { interval, map, scan, take } from 'rxjs';

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public message: string) {

    }

    public snackBarRef = inject(MatSnackBarRef);

    private duration = this.snackBarRef.containerInstance.snackBarConfig.duration ?? 0;

    public counter$ = interval(100).pipe(
        scan((accumulator) => accumulator - 100, this.duration + 100),
        take(this.duration + 1),
        map(val => 100 - Math.floor(val / this.duration * 100))
    );
}
