import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ContractorContextService } from '@core/context';
import { SnackBarService } from '@shared/interactions/snack-bar/snack-bar.service';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HasContractorGuard {
    constructor(
        private readonly contractorContextService: ContractorContextService,
        private readonly snackbarService: SnackBarService,
        private readonly router: Router
    ) { }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.contractorContextService.getSelectedContractor(false).pipe(
            map(contractor => {
                if (contractor) {
                    return true;
                } else {
                    this.snackbarService.warning('Aby skorzystać z pełnej oferty panelu należy dodać firmę.');

                    return this.router.parseUrl('/wizard');
                }
            })
        );
    }
}
