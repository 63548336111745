<textarea id="code" disabled [(ngModel)]="value">
</textarea>
<button
    mat-flat-button
    [cdkCopyToClipboard]="value"
    (click)="copy()"
    color="primary"
    class="button-alternative"
>
    <mat-icon color="accent" class="material-outlined">content_copy</mat-icon>
    {{ buttonLabel }}
</button>
