import { Component, Input } from '@angular/core';

@Component({
    selector: 'simple-chart',
    templateUrl: './simple-chart.component.html',
    styleUrls: ['./simple-chart.component.scss']
})
export class SimpleChartComponent {
    @Input()
    public value!: number;

    @Input()
    public max = 100;

    protected get bars(): boolean[] {
        return (new Array(10)).fill(false).map((item, index) => index < this.value / this.max * 10);
    }
}
