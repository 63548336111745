import { Directive, OnInit } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({
    selector: '[matInputAutofocus]'
})
export class AutofocusDirective implements OnInit {
    constructor(private matInput: MatInput) { }

    public ngOnInit(): void {
        setTimeout(() => this.matInput.focus());
    }
}
