import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { Observable } from 'rxjs';
import { catchError, first, map, retry, switchMap } from 'rxjs/operators';
import { Endpoint } from './models/enums/endpoint.enum';
import { Error } from './models/graphql-error.model';

@Injectable({
    providedIn: 'root'
})
export abstract class GraphqlService {
    constructor(
        private readonly http: HttpClient,
        private readonly authentication: AuthenticationService
    ) {

    }

    public query<ResultType = Record<string, unknown>, ParamsType = Record<string, unknown>>(
        service: Endpoint,
        query: string,
        variables?: ParamsType,
        retries = 3
    ): Observable<ResultType> {
        if (query.trim().startsWith('mutation')) {
            throw Error('Method only for query.');
        }

        return this.send(service, query, 'query', variables, retries);
    }

    public mutation<ResultType = Record<string, unknown>, ParamsType = Record<string, unknown>>(
        service: Endpoint,
        query: string,
        variables?: ParamsType
    ): Observable<ResultType> {
        if (query.trim().startsWith('query')) {
            throw Error('Method only for mutation.');
        }

        return this.send(service, query, 'mutation', variables, 0);
    }

    public send<ResultType = Record<string, unknown>, ParamsType = Record<string, unknown>>(
        service: Endpoint,
        query: string,
        type: 'mutation' | 'query',
        variables?: ParamsType,
        retries = 0
    ): Observable<ResultType> {
        return this.authentication.session().pipe(first(), switchMap(({ token }) => {
            const headers = token && new HttpHeaders({ Authorization: `Bearer ${token}` }) || {};
            const operationName = this.getOperationNameFromQuery(query);

            return this.http.post<{ data?: ResultType; errors?: Error[]; }>(service, { query, variables, type, operationName }, { headers }).pipe(
                retry(retries),
                catchError(e => {
                    if (e.error.errors) {
                        throw new Error(this.getError(e.error.errors));
                    }

                    throw e;
                }),
                map(result => {
                    if (result.errors) {
                        throw new Error(this.getError(result.errors));
                    }

                    return result.data as ResultType;
                })
            );
        }));
    }

    private getError(errors: Error[]): string {
        return errors[0].extensions?.response?.message?.join(',\n') ?? errors.map(e => e.message).join(',\n');
    }

    private getOperationNameFromQuery = (query: string): string | null => query.match(/[^query|mutation|\s]\w+/g)?.[0] ?? null;
}
