import {
    Component,
    effect,
    EventEmitter,
    input,
    Output,
    signal,
    WritableSignal
} from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { PeriodPreset, periodPresets } from '../../period-picker.interface';
import { parsePreset } from '../../parse-preset.function';

@Component({
    selector: 'app-period-picker-presets',
    templateUrl: './period-picker-presets.component.html',
    styleUrl: './period-picker-presets.component.scss'
})
export class PeriodPickerPresetsComponent {
    public selected = input.required<DateRange<Date> | null>();

    protected currentPreset: WritableSignal<PeriodPreset | null> = signal(null);

    protected periodPresets = periodPresets;

    @Output()
    public onPresetSelected = new EventEmitter<PeriodPreset>();

    protected setPeriod(preset: PeriodPreset): void {
        this.currentPreset.set(preset);
        this.onPresetSelected.emit(preset);
    }

    constructor() {
        effect(() => {
            const start = this.selected()?.start;
            const end = this.selected()?.end;

            if (!start || !end) {
                return this.currentPreset.set(null);
            }

            this.currentPreset.set(parsePreset(start, end) || null);
        }, { allowSignalWrites: true });
    }
}
