import { Observable, shareReplay } from 'rxjs';

export function Memomize(shareObservable = false): MethodDecorator {
    const value: Record<string, unknown> = {};

    return (target: object, propertyKey: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor => {
        const oryginal = descriptor.value;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        descriptor.value = function(...args: any[]): unknown {
            const key = JSON.stringify(args);

            if (value[key] === void 0) {
                value[key] = oryginal.apply(this, args);

                // If the value is an Observable, we want to share it between all subscribers
                if (shareObservable && value[key] instanceof Observable) {
                    value[key] = (value[key] as Observable<unknown>).pipe(shareReplay(1));
                }
            }

            return value[key];
        };

        return descriptor;
    };
}
