import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LazyModuleLoader } from '@core/lazy';
import { Observable, filter } from 'rxjs';
import { RightPanelComponent } from './right-panel.component';

export interface RightPanelParams {
    width?: string;
    title: string;
    module?: LazyModuleLoader;
    component?: Type<unknown>;
    managed?: boolean;
    inputs?: Record<string, unknown> | Observable<Record<string, unknown>>;
    outputs?: Record<string, unknown>;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeClose?: (component: any) => Observable<boolean>;
    class?: string;
}

@Injectable()
export class RightPanelService {
    constructor(private dialog: MatDialog) { }

    public open(params: RightPanelParams): MatDialogRef<RightPanelComponent, RightPanelParams> {
        // eslint-disable-next-line prefer-const
        let componentChildrenRef: MatDialogRef<RightPanelComponent>;

        const onClose = (): void => {
            if (params.beforeClose) {
                params.beforeClose(componentChildrenRef.componentInstance.componentRef.instance).subscribe(res => {
                    if (res) {
                        componentChildrenRef.close();
                        this.dialog.closeAll();
                    }
                });
            } else {
                componentChildrenRef.close();
                this.dialog.closeAll();
            }
        };

        componentChildrenRef = this.dialog.open(RightPanelComponent, {
            panelClass: ['slide-right', params.class ?? ''],
            height: '100%',
            width: params.width ?? '95vw',
            maxWidth: params.width ?? '1690px',
            data: { ...params, onClose },
            disableClose: true
        });

        componentChildrenRef.backdropClick().subscribe(onClose);
        componentChildrenRef.keydownEvents().pipe(filter(e => e.key === 'Escape')).subscribe(onClose);

        return componentChildrenRef;
    }

    public closeAll(): void {
        this.dialog.closeAll();
    }
}
