<mat-form-field>
    @if (label) {
        <mat-label>{{ label }}</mat-label>
    }

    <input matInput [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [min]="min" [max]="max" [formControl]="form">
    <button matSuffix mat-icon-button aria-label="Wybierz datę" [owlDateTimeTrigger]="dt1">
        <mat-icon>today</mat-icon>
    </button>
    <owl-date-time #dt1></owl-date-time>
    <mat-error matErrorMessages></mat-error>
</mat-form-field>
