<div class="radio-box-group wrap-below-{{wrapBelow()}}">
    @for (item of items(); track item.value) {
        <radio-box-button
            [type]="item.type"
            [value]="item.value"
            [checked]="item.type === 'custom' ? isCustomItemChecked() : null"
            [name]="item.name"
            [description]="item.description"
            [label]="item.label"
            [labelTextColor]="item.labelTextColor"
            [labelBackgroundColor]="item.labelBackgroundColor"
            [inputLabel]="item.inputLabel || ''"
            [unit]="item.unit"
            [format]="item.format || '1.0-0'"
            (onItemClick)="handleItemClick($event)"
            [wrapBelow]="wrapBelow()"
            class="wrap-below-{{wrapBelow()}}"
        />
    }
</div>

