<div class="header">
    <h4
        *ngIf="title"
        mat-dialog-title
    >{{title}}</h4>
    <button
        class="close-button"
        mat-icon-button
        mat-dialog-close
    >
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <div
        class="message"
        [innerHTML]="message"
    ></div>
    <mat-form-field>
        <mat-label>Wybierz</mat-label>
        <mat-select [(value)]="selected">
            <mat-option
                *ngFor="let option of options"
                [value]="option.value"
            >{{option.label}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div
    class="action-buttons"
    mat-dialog-actions
>
    <button
        color="simple"
        mat-flat-button
        mat-dialog-close
    >{{buttonCancel}}</button>
    <button
        color="primary"
        mat-flat-button
        [mat-dialog-close]="onSubmit()"
    > {{buttonAccept}} </button>
</div>