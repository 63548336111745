import { Component } from '@angular/core';

@Component({
    selector: 'app-menu-panel',
    templateUrl: './menu-panel.component.html',
    styleUrls: ['./menu-panel.component.scss']
})
export class MenuPanelComponent {

}
