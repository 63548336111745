import { FeedInterface, FeedStatus } from '@core/feed/models/feed.interface';
import { ServiceFullStatus, ServiceStatus } from '@core/services/models/service.interface';
import { StatusType } from '@shared/ui/status/status.component';

export function getFeedEntityStatusLabel(status: FeedInterface['status']): string {
    switch (status) {
        case FeedStatus.CREATED:
            return 'W trakcie konfiguracji';
        case FeedStatus.PENDING:
            return 'Wprowadzenie zmian';
        case FeedStatus.ACTIVE:
            return 'Aktywny';
        case FeedStatus.VERIFYING:
            return 'W trakcie weryfikacji';
        default:
            return 'Nieznany';
    }
}

export function getFeedEntityStatusType(status: FeedInterface['status']): StatusType {
    switch (status) {
        case FeedStatus.CREATED:
        case FeedStatus.PENDING:
        case FeedStatus.VERIFYING:
            return 'warning';
        case FeedStatus.ACTIVE:
            return 'info';
        default:
            return 'default';
    }
}

export function getFeedStatus(status: ServiceStatus): ServiceFullStatus {
    switch (status) {
        case ServiceStatus.ACTIVE: {
            return {
                status,
                info: 'Aktywna',
                color: 'info'
            };
        }
        case ServiceStatus.PROCESSING: {
            return {
                status,
                info: 'W trakcie konfiguracji',
                color: 'warning'
            };
        }
        case ServiceStatus.CREATING: {
            return {
                status,
                info: 'W trakcie tworzenia',
                color: 'warning'
            };
        }
        case ServiceStatus.INACTIVE: {
            return {
                status,
                info: 'Nieaktywny',
                color: 'warning'
            };
        }
        case ServiceStatus.VERIFYING: {
            return {
                status,
                info: 'W trakcie weryfikacji',
                color: 'warning'
            };
        }
        default: {
            return {
                status,
                info: 'Niedodany',
                color: 'error'
            };
        }
    }
}
