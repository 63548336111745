import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

export enum PanelContext {
    ClientPanel,
    Wizard,
    RetailMediaPartnerPanel,
    RetailMediaPartnerAccession,
    OrderAcceptance
}

export enum PanelTheme {
    Light,
    Dark
}

@Injectable({
    providedIn: 'root'
})
export class PanelContextService {
    private context = new BehaviorSubject<PanelContext>(this.stored ?? PanelContext.ClientPanel);

    public getContext(): Observable<PanelContext> {
        return this.context;
    }

    public setContext(value: PanelContext): void {
        this.stored = value;
        this.context.next(value);
    }

    public getTheme(): Observable<PanelTheme> {
        return this.context.pipe(map(c => {
            switch (c) {
                case PanelContext.ClientPanel: return PanelTheme.Light;
                case PanelContext.Wizard: return PanelTheme.Light;
                case PanelContext.RetailMediaPartnerPanel: return PanelTheme.Dark;
                case PanelContext.RetailMediaPartnerAccession: return PanelTheme.Dark;
                case PanelContext.OrderAcceptance: return PanelTheme.Light;
                default: return PanelTheme.Light;
            }
        }
        ));
    }

    private get stored(): PanelContext {
        return Number(sessionStorage.getItem('panel-context')) as PanelContext;
    }

    private set stored(id: PanelContext) {
        sessionStorage.setItem('panel-context', String(id));
    }
}
