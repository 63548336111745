<div class="custom-slide-toggle" matTooltipPosition="above" [matTooltip]="tooltipDescription" (click)="onToggle()">
    <span class="slider"
          [class.active]="value"
          [class.accent]="color === 'accent'"
          [class.primary]="color === 'primary'"
          [class.error]="color === 'error'"
          [class.success]="color === 'success'"
          [class.on_off]="color === 'on-off'"
    ></span>
</div>
<label *ngIf="label" (click)="onToggle()">{{ label }}</label>
