import { Component, Input } from '@angular/core';

export type StatusType = 'info' | 'warning' | 'error' | 'hint' | 'default' | 'neutral';

@Component({
    selector: 'status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})
export class StatusComponent {
    @Input()
    public type?: StatusType = 'default';

    @Input()
    public mode?: 'simple' | 'complex' = 'complex';
}
