<span
    class="example-pizza-party"
    matSnackBarLabel
> {{message}}</span>
<span
    class="close"
    matSnackBarActions
>
    <mat-spinner
        class="progress"
        [value]="counter$ | async"
        mode="determinate"
        [diameter]="30"
    ></mat-spinner>
    <button
        mat-icon-button
        matSnackBarAction
        (click)="snackBarRef.dismissWithAction()"
    > <mat-icon>close</mat-icon>
    </button>
</span>