import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { GraphqlService } from '../graphql/graphql.service';
import { Endpoint } from '../graphql/models/enums/endpoint.enum';
import { GraphqlPaginatedResponse } from '../graphql/models/graphql-paginated-response.model';
import { FeedCreateInterface, FeedInterface, FeedRemoveInterface, FeedUpdateInterface } from './models/feed.interface';
import resultModelGql from '@core/graphql/query-models/result.model.graphql';
import { GraphqlMutationResponse } from '../graphql/models/graphql-mutation-response.model';
import { ServiceStatus, ServiceType } from '../services/models/service.interface';
import feedGqlModel from './graphql/models/feed.model.graphql';
import feedReportGqlModel from './graphql/models/feed-report.model.graphql';
import assigFeedsServiceGqlMutation from './graphql/mutations/assign-service.graphql';
import createFeedGqlMutation from './graphql/mutations/create-feed.graphql';
import deleteFeedGqlMutation from './graphql/mutations/delete-feed.graphql';
import updateFeedGqlMutation from './graphql/mutations/update-feed.graphql';
import getFeedsGqlQuery from './graphql/queries/feeds.query.graphql';
import { ServiceService } from '@core/services/service.service';
import { MessageException } from '@helpers/exceptions/message.exception';

@Injectable({
    providedIn: 'root'
})
export class FeedService {
    constructor(
        private readonly graphqlService: GraphqlService,
        private readonly serviceService: ServiceService
    ) { }

    public getFeeds(contractorId: number, websiteId: number, withReports = false): Observable<FeedInterface[]> {
        const query = getFeedsGqlQuery + feedGqlModel + feedReportGqlModel;

        return this.graphqlService.query<{ contractor: { website: { serviceVast: { feeds: GraphqlPaginatedResponse<FeedInterface>; }; }; }; }>(
            Endpoint.GatewayApi,
            query,
            { websiteId, contractorId, withReports }
        ).pipe(
            map(res => res.contractor.website.serviceVast?.feeds?.items.sort((a, b) => a.id - b.id) ?? [])
        );
    }

    public assignService(contractorId: number, websiteId: number, feedIds: number[], service: ServiceType): Promise<GraphqlMutationResponse> {
        const query = assigFeedsServiceGqlMutation + resultModelGql;
        const mapped = this.getMappedService(service);

        if (mapped === null) {
            throw Error('Invalid service. Check mapping here!');
        }

        return lastValueFrom(this.graphqlService.mutation<{
            contractor: { website: { serviceVast: { feeds: { assignService: GraphqlMutationResponse; }; }; }; };
        }, {
            ids: number[];
            service: string;
            websiteId: number;
            contractorId: number;
        }>(Endpoint.GatewayApi, query, {
            ids: feedIds,
            service: mapped,
            websiteId,
            contractorId
        }).pipe(
            map(res => res.contractor.website.serviceVast?.feeds.assignService)
        ));
    }

    public createFeed(feed: FeedCreateInterface): Promise<number> {
        const query = createFeedGqlMutation + resultModelGql;

        return lastValueFrom(this.graphqlService.mutation<{
            contractor: { website: { serviceVast: { feed: { create: GraphqlMutationResponse<{ id: number; }>; }; }; }; };
        }, FeedCreateInterface>(
            Endpoint.GatewayApi,
            query,
            {
                websiteId: feed.websiteId,
                contractorId: feed.contractorId,
                url: feed.url,
                name: feed.name,
                scheduleTime: feed.scheduleTime
            }
        ).pipe(
            map(res => res.contractor.website.serviceVast?.feed?.create?.data.id)
        ));
    }

    public updateFeed(feed: FeedUpdateInterface): Promise<boolean> {
        const query = updateFeedGqlMutation + resultModelGql;

        return lastValueFrom(this.graphqlService.mutation<{
            contractor: { website: { serviceVast: { feed: { update: GraphqlMutationResponse<never>; }; }; }; };
        }, FeedUpdateInterface>(
            Endpoint.GatewayApi,
            query,
            {
                websiteId: feed.websiteId,
                contractorId: feed.contractorId,
                id: feed.id,
                url: feed.url,
                name: feed.name,
                scheduleTime: feed.scheduleTime
            }
        ).pipe(
            map(res => res.contractor.website.serviceVast?.feed?.update?.status)
        ));
    }

    public removeFeed(feed: FeedRemoveInterface): Promise<boolean> {
        const query = deleteFeedGqlMutation + resultModelGql;

        return lastValueFrom(this.graphqlService.mutation<{
            contractor: { website: { serviceVast: { feed: { delete: GraphqlMutationResponse<never>; }; }; }; };
        }, FeedRemoveInterface>(
            Endpoint.GatewayApi,
            query,
            {
                id: feed.id,
                websiteId: feed.websiteId,
                contractorId: feed.contractorId
            }
        ).pipe(
            map(res => res.contractor.website.serviceVast?.feed?.delete?.status)
        ));
    }

    private getMappedService(service: ServiceType): string | null {
        switch (service) {
            case ServiceType.SKAPIEC:
                return 'skp';
            case ServiceType.LAMODA:
                return 'lamoda_onetads';
            default:
                return null;
        }
    }

    public async createFeedService(feedData: Pick<FeedCreateInterface, 'contractorId' | 'websiteId'>): Promise<void> {
        try {
            const result = await this.serviceService.createService({
                type: ServiceType.FEED,
                config: null,
                websiteId: feedData.websiteId,
                contractorId: feedData.contractorId
            });

            if (result) {
                const processing = await this.serviceService.ensureServiceStatus({
                    type: ServiceType.FEED,
                    websiteId: feedData.websiteId,
                    contractorId: feedData.contractorId
                }, [ServiceStatus.PROCESSING, ServiceStatus.ACTIVE, ServiceStatus.VERIFYING]);

                if (!processing) {
                    throw new MessageException('Uruchomienie usługi trwało dłużej niż zwykle. Proszę spróbować dodać feed produktowy raz jeszcze.');
                }
            } else {
                throw new MessageException('Wystąpił błąd podczas uruchamiania usługi. Proszę spróbować później.');
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            if (e instanceof MessageException) {
                throw new MessageException(e.message);
            } else {
                throw new MessageException('Wystąpił błąd podczas uruchamiania usługi. Prosze spróbować później.');
            }
        }
    }
}
