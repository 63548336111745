import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import Chart, { ChartDataset } from 'chart.js/auto';

@Component({
    selector: 'no-data-chart',
    templateUrl: './no-data-chart.component.html',
    styleUrls: ['./no-data-chart.component.scss']
})
export class NoDataChartComponent implements AfterViewInit {
    @ViewChild('canvas')
    protected canvasRef!: ElementRef;

    public ngAfterViewInit(): void {
        const colors = {
            grey: {
                default: 'rgb(224,224,224)',
                half: 'rgba(162,162,162,0.3)',
                quarter: 'rgba(79,79,79,0.15)',
                zero: 'rgba(149, 76, 233, 0)'
            }
        };

        function randomInRange(value: number, fluctuation: number): number {
            const minValue = value - fluctuation;
            const maxValue = value + fluctuation;

            return minValue + Math.random() * (maxValue - minValue);
        }

        const datas = Array.from({ length: 50 }, () => {
            const value = randomInRange(0.5, 0.1);

            return Math.max(0, Math.min(1, value));
        });

        const labels = Array.from({ length: 50 }, (x, index: number) => index + 1);

        const canvas = this.canvasRef.nativeElement;
        const ctx = canvas.getContext('2d');

        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, colors.grey.half);
        gradient.addColorStop(1, colors.grey.zero);

        const options = {
            responsive: false,
            maintainAspectRatio: false,
            aspectRatio: 4,
            plugins: {
                title: {
                    display: false
                },
                tooltip: {
                    enabled: false
                },
                legend: {
                    display: false
                }
            },
            scales: {
                x: {
                    display: false
                },
                y: {
                    display: false
                }
            }
        };

        new Chart(ctx, {
            type: 'line',
            data: {
                labels,
                datasets: [
                    {
                        fill: true,
                        backgroundColor: gradient,
                        pointBackgroundColor: colors.grey.default,
                        borderColor: colors.grey.default,
                        data: datas,
                        lineTension: 0.2,
                        borderWidth: 2,
                        pointRadius: 3
                    } as ChartDataset<'line', number[]>
                ]
            },
            options
        });
    }
}
