import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InfoDialogParams {
    message: string;
    title?: string;
    buttonOk: string;
    width?: number;
}
@Component({
    selector: 'app-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {
    public title: string | undefined;

    public message: string;

    public buttonOk: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: InfoDialogParams,
        public dialogRef: MatDialogRef<InfoDialogComponent>
    ) {
        this.title = data.title;
        this.message = data.message;
        this.buttonOk = data.buttonOk;
    }
}
