import { Injectable } from '@angular/core';
import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import { Observable, distinctUntilChanged } from 'rxjs';

const BUCKET_OPTIONS = {
    endpoint: 'ocdn.eu',
    computeChecksums: false,
    region: 'ocdn',
    s3ForcePathStyle: true,
    signatureVersion: 'v4',
    sslEnabled: true
};

const BUCKETS = {
    'ecommerce': {
        accessKeyId: '3SLYNEEWCSNSFFRPOC5R',
        secretAccessKey: 'sztOKGLk2i8CsylAh4Ax5k8Z02xGDMOAaOTWk115'
    },
    'onetads-temporary': {
        accessKeyId: '0EMS8KAKSWO1IRJEEEXE',
        secretAccessKey: 'zRTpOVAowBeiwCeFUrRNfOUUOFmNhsbJ31AeNf13'
    }
};

export type Bucket =
    'ecommerce' |
    'onetads-temporary';

export enum UploadStatus {
    UPLOADING = 'UPLOADING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE'
}

export interface UploadResponse {
    url: string | null;
    status: UploadStatus;
    percentage: number;
}

@Injectable({
    providedIn: 'root'
})
export class OCDNService {
    private readonly BASE64_MARKER = ';base64,';

    public upload(file: File, bucketName: Bucket = 'ecommerce'): Observable<UploadResponse> {
        const ext = file.type.split('/')[1];
        const params = {
            ACL: 'public-read',
            Bucket: bucketName,
            Key: `${bucketName}_${Date.now()}/${file.name.replace(/\.[^.]*$/, '')}.${ext}`,
            Body: file,
            ContentType: file.type,
            Metadata: {
                'Content-Type': file.type
            }
        };

        return new Observable<UploadResponse>(subscriber => {
            subscriber.next({ url: null, status: UploadStatus.UPLOADING, percentage: 0 });

            import('aws-sdk/clients/s3').then(S3 => {
                const bucket = new S3.default(Object.assign({}, BUCKET_OPTIONS, BUCKETS[bucketName]));
                const request = bucket.upload(params);

                request.on('httpUploadProgress', (e) => {
                    subscriber.next({ url: null, status: UploadStatus.UPLOADING, percentage: Math.round(e.loaded / e.total * 100) });
                });

                request.promise()
                    .then(res => {
                        subscriber.next({ url: res.Location, status: UploadStatus.SUCCESS, percentage: 100 });
                        subscriber.complete();
                    })
                    .catch(err => {
                        subscriber.next({ url: null, status: UploadStatus.FAILURE, percentage: 0 });
                        subscriber.error(err);
                    });
            });
        }).pipe(
            distinctUntilChanged((prev, curr) => prev.status === curr.status && prev.percentage === curr.percentage)
        );
    }

    public uploadFile(file: { body: string; type: string; }, bucketName: Bucket = 'ecommerce'): Promise<ManagedUpload.SendData> {
        if (!BUCKETS[bucketName]) {
            throw new Error(`Bucket ${bucketName} not configured!`);
        }

        if (!file.body.includes(this.BASE64_MARKER)) {
            throw new Error('Plik uszkodzony');
        }

        const binaryData = this.convertDataURIToBinary(file.body).buffer;

        const params = {
            Bucket: bucketName,
            Key: `${bucketName}_${Date.now()}`,
            Body: binaryData,
            ACL: 'public-read',
            ContentType: file.type,
            Metadata: {
                'Content-Type': file.type
            }
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return import('aws-sdk/clients/s3').then((S3: any) => {
            const bucket = new S3.default(Object.assign(BUCKET_OPTIONS, BUCKETS[bucketName]));

            return bucket.upload(params).promise();
        });
    }

    private convertDataURIToBinary(rawBase64: string): Uint8Array {
        const base64Index = rawBase64.indexOf(this.BASE64_MARKER) + this.BASE64_MARKER.length;
        const base64 = rawBase64.substring(base64Index);
        const raw = window.atob(base64);
        const rawLength = raw.length;
        const array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }

        return array;
    }
}
