import { Component, ComponentRef, Inject, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LazyLoaderService } from '@core/lazy/lazy-loader.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { RightPanelParams } from './right-panel.service';

@Component({
    selector: 'right-panel',
    templateUrl: './right-panel.component.html',
    styleUrls: ['./right-panel.component.scss']
})

export class RightPanelComponent {
    protected title = '';

    protected managed = true;

    private closed$ = new Subject();

    @ViewChild('target', { read: ViewContainerRef, static: false })
    private viewContainerRef!: ViewContainerRef;

    @ViewChild('header', { read: ViewContainerRef, static: false })
    private headerContainerRef!: ViewContainerRef;

    @ViewChild('loader', { read: TemplateRef, static: true })
    private loaderTemplateRef!: TemplateRef<unknown>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public componentRef!: ComponentRef<any>;

    constructor(
        public dialog: MatDialog,
        private readonly lazyLoader: LazyLoaderService,
        @Inject(MAT_DIALOG_DATA) public data: RightPanelParams & { onClose: () => void; }
    ) {
        this.title = this.data.title;
        this.managed = this.data.managed ?? true;
    }

    public async ngAfterViewInit(): Promise<void> {
        this.viewContainerRef.createEmbeddedView(this.loaderTemplateRef);

        if (this.data.module) {
            this.componentRef = await this.lazyLoader.load(this.data.module, this.viewContainerRef);
        } else if (this.data.component) {
            this.viewContainerRef.clear();
            this.componentRef = this.viewContainerRef.createComponent(this.data.component);
        } else {
            throw new Error('You HAVE TO provide module or component param');
        }

        if (this.componentRef.instance.headerTemplateRef) {
            this.headerContainerRef.clear();
            this.headerContainerRef.createEmbeddedView(this.componentRef.instance.headerTemplateRef);
        }

        if (this.data.inputs) {
            if (this.data.inputs instanceof Observable) {
                this.data.inputs.pipe(takeUntil(this.closed$)).subscribe(inputs => {
                    for (const propKey of Object.keys(inputs)) {
                        this.componentRef.instance[propKey] = inputs[propKey];
                    }
                });
            } else {
                for (const propKey of Object.keys(this.data.inputs)) {
                    this.componentRef.instance[propKey] = this.data.inputs[propKey];
                }
            }
        }

        if (this.data.outputs) {
            for (const propKey of Object.keys(this.data.outputs)) {
                this.componentRef.instance[propKey] = this.data.outputs[propKey];
            }
        }

        this.componentRef.changeDetectorRef.detectChanges();
    }

    public onClose(): void {
        this.data.onClose();
        this.closed$.next(true);
    }
}
