<div
    class="status"
    [ngClass]="mode === 'complex' ? ['complex', type] : {}"
>
    <mat-icon
        class="weight-icon"
        [ngClass]="mode === 'complex' ? ['complex', type] : type"
        [class]="type === 'default' ? 'material-icons-outlined' : ''"
    > circle </mat-icon>
    <span [ngClass]="mode === 'complex' ? ['complex', type] : {}">
        <ng-content></ng-content>
    </span>
</div>