import { Component, effect, input, OnInit, signal } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { RadioBoxButtonComponentInterface, wrapBelow } from '@shared/interactions/radio-box-button/radio-box-button.component';

@Component({
    selector: 'radio-box-group',
    templateUrl: './radio-box-group.component.html',
    styleUrl: './radio-box-group.component.scss'
})
export class RadioBoxGroupComponent implements ControlValueAccessor, OnInit {
    protected control: FormControl = new FormControl();

    public items = input.required<RadioBoxButtonComponentInterface[]>();

    public wrapBelow = input<wrapBelow>('md');

    protected currentActiveType = signal<'fixed' | 'custom'>('fixed');

    protected isCustomItemChecked = signal<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    private onChange = (url: string): void => { };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onTouched = (): void => { };

    constructor(
        private controlDirective: NgControl
    ) {
        this.controlDirective.valueAccessor = this;

        effect(() => {
            this.updateCustomItemChecked();
        }, { allowSignalWrites: true });
    }

    public ngOnInit(): void {
        this.control = this.controlDirective.control as FormControl;

        this.currentActiveType.set(
            this.findItemByControlValue() ? 'fixed' : 'custom'
        );
    }

    private updateCustomItemChecked(): void {
        if (this.currentActiveType() === 'custom') {
            this.isCustomItemChecked.set(true);
        } else {
            this.isCustomItemChecked.set(
                !this.findItemByControlValue()
            );
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(val: string): void {
        if (!this.control.value) {
            this.control.setValue(val);
        }
    }

    protected handleItemClick(type: 'fixed' | 'custom'): void {
        this.isCustomItemChecked.set(type === 'custom');
        this.currentActiveType.set(type);
    }

    private findItemByControlValue(): RadioBoxButtonComponentInterface | undefined {
        return this.items().find(item => {
            return item.type !== 'custom' && item.value === this.control.value;
        });
    }
}
