import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadcrumbsItem } from './interfaces/breadcrumbs-item.interface';
import { BehaviorSubject, filter } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbsService {
    public breadcrumbs = new BehaviorSubject<BreadcrumbsItem[]>([]);

    constructor(
        private readonly router: Router
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            const items = this.getBreadcrumbs(this.router.routerState.root);
            const unique = this.getUnique(items);

            this.breadcrumbs.next(unique);
        });
    }

    public append(item: BreadcrumbsItem): void {
        if (!this.breadcrumbs.value.some(i => i.label === item.label)) {
            this.breadcrumbs.next([...this.breadcrumbs.value, item]);
        }
    }

    private getBreadcrumbs(route: ActivatedRoute): BreadcrumbsItem[] {
        const items: BreadcrumbsItem[] = [];

        if (route.snapshot.title) {
            items.push({ label: route.snapshot.title, url: `/${route.snapshot.pathFromRoot.flatMap(r => r.url).join('/')}` });
        }

        if (route.snapshot.data.breadcrumbs) {
            items.push(...route.snapshot.data.breadcrumbs);
        }

        if (route.firstChild) {
            items.push(...this.getBreadcrumbs(route.firstChild));
        }

        return items;
    }

    private getUnique(items: BreadcrumbsItem[]): BreadcrumbsItem[] {
        const unique = new Map<string, BreadcrumbsItem>();

        items.forEach(item => {
            const key = item.label;

            if (unique.has(key) === false) {
                unique.set(key, item);
            }
        });

        return [...unique.values()];
    }
}
