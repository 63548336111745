import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication';
import { combineLatest, filter, map } from 'rxjs';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet *ngIf="loaded$ | async; else loading"></router-outlet>
        <ng-template #loading>
            <app-loader></app-loader>
        </ng-template>
    `,
    styles: []
})
export class AppComponent {
    private loadingUser$ = this.authentication.user().pipe(map(() => true));

    private loadingData$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd), map(() => true));

    protected loaded$ = combineLatest([this.loadingData$, this.loadingUser$]).pipe(map(([l1, l2]) => l1 && l2));

    constructor(
        private readonly authentication: AuthenticationService,
        private readonly router: Router
    ) { }
}
