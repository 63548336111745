<mat-card class="card wrap-below-{{wrapBelow()}}"  (click)="handleOnClick()" [class.checked]="checked() || radioButton.checked">
    <mat-radio-button [value]="value()" [checked]="checked()" class="radio wrap-below-{{wrapBelow()}}" #radioButton />
    <div class="content wrap-below-{{wrapBelow()}}" [class.custom]="type() === 'custom'">
        <div class="value" [class.hidden]="type() !== 'fixed'">
            {{ value() | number: format() }}{{ unit() }}
        </div>
        <div
            class="value {{radioButton.checked ? 'hidden-above-' + wrapBelow() : ''}}"
            [class.hidden]="type() === 'fixed'"
        >?</div>
        <mat-form-field class="input" [class.hidden]="type() === 'fixed' || !radioButton.checked">
            <mat-label>{{ inputLabel() }}</mat-label>
            <input matInput type="number" [formControl]="control" #inputElement />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <div class="name">{{ name() }}</div>
        <div class="description">{{ description() }}</div>
    </div>

    @if (label()) {
        <div class="label-wrapper wrap-below-{{wrapBelow()}}">
            <div class="label" [style.color]="labelTextColor()" [style.background-color]="labelBackgroundColor()">{{ label() }}</div>
        </div>
    }
</mat-card>
