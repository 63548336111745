<canvas
    #canvas
    height="200"
></canvas>
<div class="info">
    <p class="header">
        <ng-content select="[header]"></ng-content>
    </p>
    <p class="sub-header">
        <ng-content select="[sub-header]"></ng-content>
    </p>
</div>
