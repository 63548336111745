import { HttpClientModule } from '@angular/common/http';
import { EnvironmentProviders, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { AsyncJobsService } from './async-jobs.service';

@NgModule({
    imports: [HttpClientModule],
    providers: [AsyncJobsService]
})
export class AsyncJobsModule {
    public static forRoot(cfg: { providers: (Provider | EnvironmentProviders)[]; }): ModuleWithProviders<AsyncJobsModule> {
        return {
            ngModule: AsyncJobsModule,
            providers: cfg.providers
        };
    }
}
