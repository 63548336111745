import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogParams {
    message: string;
    title?: string;
    buttonAccept?: string;
    buttonCancel?: string;
    width?: number;
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    public title: string;

    public message: string;

    public buttonAccept: string;

    public buttonCancel: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogParams,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>
    ) {
        this.title = data.title ?? 'Potwierdzenie';
        this.message = data.message;
        this.buttonAccept = data.buttonAccept ?? 'Tak';
        this.buttonCancel = data.buttonCancel ?? 'Nie';
    }

    public onSubmit(): boolean {
        return true;
    }
}
