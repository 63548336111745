<mat-form-field>
    <mat-label [helpTooltip]="tooltip">{{label}}</mat-label>
    <input
        readonly
        type="url"
        matInput
        [formControl]="control"
    >
    <mat-error matErrorMessages></mat-error>
    <mat-hint>{{message}}</mat-hint>
    <button
        *ngIf="percentage === null"
        mat-stroked-button
        matSuffix
        color="accent"
        class="upload-button"
        (click)="buttonLabel.click()"
    > <label
            #buttonLabel
            [for]="inputId"
            click-stop-propagation
        >{{buttonText}}</label></button>

    <mat-spinner
        *ngIf="percentage !== null"
        mode="determinate"
        color="accent"
        [value]="percentage"
        [diameter]="30"
    />
    <input
        [id]="inputId"
        type="file"
        (change)="onImageAdd($event)"
        [accept]="acceptTypes"
        style="display: none;"
    />
</mat-form-field>
