import { Component } from '@angular/core';

@Component({
    selector: 'right-panel-content',
    template: `
        <div class="wrapper">
            <ng-content />
        </div>
    `,
    styleUrls: ['./right-panel-content.component.scss']
})
export class RightPanelContentComponent {
}
