<div
    class="dropzone"
    [class.dropzone--disabled]="control.disabled"
    (drop)="onFileDropped($event)"
    (dragover)="dragOver($event)"
>
    <div class="title">
        <ng-container *ngIf="fileMetadata.name">Aby zmienić GRAFIKĘ upuść plik tutaj</ng-container>
        <ng-container *ngIf="!fileMetadata.name">Upuść GRAFIKĘ tutaj</ng-container>

        lub <a href="#">
            <label
                [for]="inputId"
                click-stop-propagation
            >przeglądaj</label>
        </a>
    </div>
    <div
        class="file"
        *ngIf="fileMetadata.name && fileMetadata.ratio"
    >
        <button
            mat-icon-button
            class="file__remove"
            (click)="onFileRemove()"
        >
            <mat-icon>close</mat-icon>
        </button>
        <span class="file__text">{{ fileMetadata.ratio }} | {{ fileMetadata.name }}</span>
    </div>
    <ul>
        <li>
            Typ pliku:
            <b>
                @for (type of fileTypes; track fileTypes; let i = $index) {
                    {{ type }}@if (i < fileTypes.length - 1) {,}
                }
            </b>
        </li>
        <li>Waga pliku <b>do {{ maxSizeKb }} kB</b></li>
        <li>
            Proporcje obrazu:
            <b>
                {{ ratio }}
                <ng-container [ngSwitch]="ratio">
                    <ng-container *ngSwitchCase="'16:7'"> (np. 1600x700px)</ng-container>
                    <ng-container *ngSwitchCase="'7:9'"> (np. 700x900px)</ng-container>
                </ng-container>
            </b>
        </li>
    </ul>
    <input
        [id]="inputId"
        type="file"
        accept="image/*"
        (change)="onFileAdd($event)"
        style="display: none;"
    />
    <div
        class="dropzone__loader"
        *ngIf="isLoading"
    >
        <mat-spinner
            class="file__loader"
            diameter="64"
        />
    </div>
</div>
