import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication';
import { ContractorService } from '@core/contractors';
import { SnackBarService } from '@shared/interactions/snack-bar/snack-bar.service';

@Component({
    selector: 'app-invitation',
    templateUrl: './invitation.component.html',
    styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private contractorService: ContractorService,
        private readonly snackbarService: SnackBarService,
        protected readonly authenticationService: AuthenticationService
    ) { }

    public ngOnInit(): void {
        this.route.queryParams.subscribe(async params => {
            const token = params.token;

            if (!token) {
                return this.authenticationService.logout();
            }

            const response = await this.contractorService.acceptInvitation(token);

            if (response.result?.status === true) {
                this.snackbarService.success('Uprawnienia dodane pomyślnie.');

                return this.router.navigate(['/']);
            }

            this.snackbarService.alert('Wystąpił błąd podczas dodawania uprawnień.');

            return setTimeout(() => {
                this.authenticationService.logout();
            }, 2000);
        });
    }
}
