import { ComponentRef, Directive, ElementRef, Input, ViewContainerRef } from '@angular/core';
import { LoadingComponent } from './loading.component';

@Directive({
    selector: '[pending]'
})
export class PendingDirective {
    @Input('pending')
    public set pending(value: boolean) {
        if (!['A', 'BUTTON'].includes(this.element.nativeElement.tagName)) {
            return;
        }

        if (value) {
            this.renderLoading();
        } else {
            this.removeLoading();
        }
    }

    constructor(private element: ElementRef,
        private viewContainerRef: ViewContainerRef
    ) { }

    private componentRef: ComponentRef<LoadingComponent> | undefined;

    private renderLoading(): void {
        this.componentRef = this.viewContainerRef.createComponent(LoadingComponent);
        this.element.nativeElement.appendChild(this.componentRef.location.nativeElement);
        this.element.nativeElement.disabled = true;
        this.element.nativeElement.classList.add('pending');

        const icon = this.element.nativeElement.querySelector('mat-icon');

        if (icon) {
            icon.style.display = 'none';
        }
    }

    private removeLoading(): void {
        this.componentRef?.location.nativeElement.remove();
        this.element.nativeElement.disabled = false;
        this.element.nativeElement.classList.remove('pending');

        const icon = this.element.nativeElement.querySelector('mat-icon');

        if (icon) {
            icon.style.removeProperty('display');
        }
    }
}
