<div class="mat-right-dialog">
    <div class="header">
        <h4 class="title"> {{title}} </h4>
        <ng-container #header></ng-container>
        <button
            mat-icon-button
            (click)="onClose()"
        > <mat-icon
                class="close-button"
                id="close-icon"
            >close</mat-icon></button>
    </div>

    <right-panel-content *ngIf="managed">
        <ng-container #target></ng-container>
    </right-panel-content>

    <ng-container #target *ngIf="!managed" />
    <ng-template #loader>
        <app-loader />
    </ng-template>
</div>
