import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarComponent } from './snack-bar.component';

export type SnackBarStatus = 'info' | 'warning' | 'alert' | 'success';

@Injectable()
export class SnackBarService {
    constructor(private snackBar: MatSnackBar) { }

    public info(message: string, config: MatSnackBarConfig<string> = new MatSnackBarConfig<string>()): void {
        this.show(message, config, 'info');
    }

    public success(message: string, config: MatSnackBarConfig<string> = new MatSnackBarConfig<string>()): void {
        this.show(message, config, 'success');
    }

    public warning(message: string, config: MatSnackBarConfig<string> = new MatSnackBarConfig<string>()): void {
        this.show(message, config, 'warning');
    }

    public alert(message: string, config: MatSnackBarConfig<string> = new MatSnackBarConfig<string>()): void {
        this.show(message, config, 'alert');
    }

    private show(message: string, config: MatSnackBarConfig<string>, status: SnackBarStatus): void {
        if (status === 'warning' || status === 'alert') {
            config.duration = 15000;
        } else {
            config.duration = config.duration || 5000;
        }

        config.panelClass = [...(config?.panelClass ?? []), 'custom-snack-bar', status];
        config.verticalPosition = 'bottom';
        config.horizontalPosition = 'right';
        config.data = message;

        this.snackBar.openFromComponent<SnackBarComponent, string>(SnackBarComponent, config);
    }
}
