import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, ValidationErrors } from '@angular/forms';
import { LoggerService } from '@core/logger/logger.service';
import { Bucket, OCDNService, UploadStatus } from '@core/ocdn/ocdn.service';
import { last } from 'rxjs';

@Component({
    selector: 'file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements ControlValueAccessor, OnInit {
    protected control: FormControl = new FormControl();

    protected percentage: number | null = null;

    @Input()
    public bucket: Bucket = 'ecommerce';

    @Input()
    public label = 'Dodaj plik';

    @Input()
    public buttonText = 'Wybierz plik';

    @Input()
    public recommendedHeightMin?: number;

    @Input()
    public recommendedHeightMax?: number;

    @Input()
    public recommendedWidthMin?: number;

    @Input()
    public recommendedWidthMax?: number;

    @Input()
    public maxSizeMb?: number;

    @Input()
    public acceptTypes = 'image/*';

    @Input()
    public message?: string;

    @Input()
    public tooltip?: string;

    @Output()
    public onProgress = new EventEmitter<number>();

    protected inputId = `file-upload-${Math.random().toString(36).slice(2, 11)}`;

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    private onChange = (url: string): void => { };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onTouched = (): void => { };

    constructor(
        private controlDirective: NgControl,
        private readonly logger: LoggerService,
        private readonly ocdnService: OCDNService
    ) {
        this.controlDirective.valueAccessor = this;
    }

    public ngOnInit(): void {
        this.control = this.controlDirective.control as FormControl;

        if (!this.bucket) {
            throw Error('You must pass bucket by input!');
        }
    }

    public writeValue(val: string): void {
        if (!this.control.value) {
            this.control.setValue(val);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public markAsTouched(): void {
        this.onTouched();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onImageAdd(event: any): void {
        if (event.target.files?.[0]) {
            this.processPhoto(event.target.files[0]);
        }
    }

    private processPhoto(file: File): void {
        const reader = new FileReader();

        if (this.maxSizeMb && this.maxSizeMb * 1e6 < file.size) {
            this.setError({ fileOverSize: { max: this.maxSizeMb } });

            return;
        }

        this.onProgress.emit(0);

        reader.addEventListener('load', (e) => {
            if (e.target?.result) {
                this.control.setErrors(null);
                this.percentage = 0;
                this.onProgress.emit(0);

                const image = new Image();

                image.src = e.target.result as string;

                image.onload = (): void => {
                    const imageWidth = image.width;
                    const imageHeight = image.height;

                    if (this.acceptTypes === 'image/*') {
                        if (this.recommendedHeightMax && imageHeight && imageHeight > this.recommendedHeightMax) {
                            this.setError({ imageOverSizeY: { max: this.recommendedHeightMax } });
                        } else if (this.recommendedHeightMin && imageHeight && imageHeight < this.recommendedHeightMin) {
                            this.setError({ imageUnderSizeY: { min: this.recommendedHeightMin } });
                        } else if (this.recommendedWidthMax && imageWidth && imageWidth > this.recommendedWidthMax) {
                            this.setError({ imageOverSizeX: { max: this.recommendedWidthMax } });
                        } else if (this.recommendedWidthMin && imageWidth && imageWidth < this.recommendedWidthMin) {
                            this.setError({ imageUnderSizeX: { max: this.recommendedWidthMin } });
                        }
                    }

                    if (this.control.valid) {
                        this.ocdnService.upload(file, this.bucket).pipe(last()).subscribe({
                            next: res => {
                                this.onProgress.emit(this.percentage = res.percentage);

                                if (res.status === UploadStatus.SUCCESS) {
                                    this.onChange(res.url as string);
                                    this.markAsTouched();
                                    this.control.setValue(res.url);
                                    this.message = 'Plik załadowany pomyślnie';
                                    this.percentage = null;
                                }
                            },
                            error: err => {
                                this.logger.error('UPLOAD_FILE_ERROR', err);
                                this.message = 'Wystąpił błąd podczas wgrywania pliku';
                                this.percentage = null;
                            }
                        });
                    }
                };

                image.onerror = (err): void => {
                    this.logger.error('LOAD_FILE_ERROR', err);
                    this.message = 'Wystąpił błąd podczas wczytywania pliku';
                    this.percentage = null;
                };
            } else {
                this.message = 'Wystąpił błąd podczas wgrywania pliku';
            }
        });

        reader.readAsDataURL(file);
    }

    private setError(errors: ValidationErrors): void {
        this.control.setErrors({ ...errors, ...this.control.errors });
        this.control.markAsDirty();
        this.control.markAsTouched();
    }

    public onImageRemove(): void {
        this.onChange('');
        this.markAsTouched();
        this.control.setValue('');
    }
}
