<button class="trigger" [matMenuTriggerFor]="billingModel">
    {{ label }}
    <mat-icon class="material-icons-outlined" iconPositionEnd>arrow_drop_down</mat-icon>
</button>
<mat-menu #billingModel class="dropdown-button-menu">
    @for (item of items(); track item.value) {
        <button
            mat-menu-item
            role="menuitemradio"
            [class.active]="control.value === item.value"
            (click)="setValue(item.value)"
        >
            <div class="label">
                {{ item.label }}
                @if (item.recommended) {
                    <span class="recommended">Rekomendowane</span>
                }
            </div>
            @if (item.description) {
                <div class="description">
                    {{ item.description }}
                </div>
            }
    </button>
    }
</mat-menu>
