export enum ContractorStatus {
    verifying = 0,
    pending = 1,
    accepted = 2,
    rejected = 3
}

export interface ContractorAccessInterface {
    isContractorOwner: boolean;
    isContractorAdmin: boolean;
    canEditAnyWebsite: boolean;
    canEditContractor: boolean;
    canViewContractor: boolean;
    canViewPermissions: boolean;
    canViewContractorFinances: boolean;
    canViewWebsiteListPermissions: boolean;
    canCreatePermissionForAdmin: boolean;
    canCreatePermissionForOwner: boolean;
    canCreatePermissionForWebsiteAnalyst: boolean;
    canCreatePermissionForWebsiteOperator: boolean;
    canDeletePermissionForAdmin: boolean;
    canDeletePermissionForOwner: boolean;
    canDeletePermissionForWebsiteAnalyst: boolean;
    canDeletePermissionForWebsiteOperator: boolean;
}
