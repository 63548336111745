<mat-form-field appearance="outline" class="slim" [matMenuTriggerFor]="menu" #calendarTrigger="matMenuTrigger">
    <mat-label (click)="$event.stopPropagation()">Okres</mat-label>
    <input matInput class="value-input" [value]="valueText()" placeholder="Okres" readonly>
    <mat-icon matIconSuffix>arrow_drop_down</mat-icon>
    <mat-menu #menu="matMenu" xPosition="before" class="date-range-calendar" (closed)="onCalendarClosed()">
        <div class="calendar-wrapper period-picker" (click)="$event.stopPropagation();">
            <app-period-picker-presets [selected]="currentRange()" (onPresetSelected)="handlePresetSelected($event)" />
            <div class="calendars">
                <div class="calendar">
                    <div class="calendar__header">
                        <button mat-icon-button (click)="previousClicked()">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                        </button>
                        <span class="calendar__header-label">{{ previousMonthLabel() }}</span>
                        <button mat-icon-button style="visibility: hidden;">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>
                    </div>
                    <mat-calendar
                        [selected]="selected"
                        [startAt]="previousMonth()"
                        (selectedChange)="selectedChange($event)"
                        [headerComponent]="customCalendarHeader"
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        [dateClass]="dateClass()"
                    ></mat-calendar>
                </div>
                <div class="calendar">
                    <div class="calendar__header">
                        <button mat-icon-button style="visibility: hidden;">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                        </button>
                        <span class="calendar__header-label">{{ currentMonthLabel() }}</span>
                        <button mat-icon-button (click)="nextClicked()">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>
                    </div>
                    <mat-calendar
                        [selected]="selected"
                        [startAt]="currentMonth()"
                        (selectedChange)="selectedChange($event)"
                        [headerComponent]="customCalendarHeader"
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                    ></mat-calendar>
                </div>
            </div>
            <div class="actions">
                <button mat-flat-button (click)="cancel()">Odrzuć</button>
                <button mat-flat-button color="primary" (click)="apply()">
                    <mat-icon>check</mat-icon> Zatwierdź
                </button>
            </div>
        </div>
    </mat-menu>
</mat-form-field>


