import { Component, input } from '@angular/core';

@Component({
    selector: 'info-box',
    templateUrl: './info-box.component.html',
    styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent {
    public type = input<'info' | 'warning' | 'error' | 'neutral'>('neutral');

    public marginBottom = input<string>();
}
