import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { sentryInit } from '../sentry.config';

// We don't want to allow load application i iframe
if (top === window) {
    if (environment.production) {
        enableProdMode();
    }

    sentryInit();

    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
}

window.global = window;
