import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SelectDialogOption {
    value: string | number;
    label: string;
}

export interface SelectDialogParams {
    message: string;
    title?: string;
    buttonAccept?: string;
    buttonCancel?: string;
    width?: number;
    options: SelectDialogOption[];
}

@Component({
    selector: 'app-select-dialog',
    templateUrl: './select-dialog.component.html',
    styleUrls: ['./select-dialog.component.scss']
})
export class SelectDialogComponent {
    protected title: string;

    protected message: string;

    protected buttonAccept: string;

    protected buttonCancel: string;

    protected options: SelectDialogOption[] = [];

    protected selected?: SelectDialogOption;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SelectDialogParams,
        public dialogRef: MatDialogRef<SelectDialogComponent>
    ) {
        this.title = data.title ?? 'Wybierz';
        this.message = data.message;
        this.buttonAccept = data.buttonAccept ?? 'OK';
        this.buttonCancel = data.buttonCancel ?? 'Anuluj';
        this.options = data.options ?? [];
    }

    public onSubmit(): SelectDialogOption | null {
        return this.selected ?? null;
    }
}
