import { ServiceFullStatus, ServiceStatus } from '@core/services/models/service.interface';

export function getPixelStatus(status: ServiceStatus): ServiceFullStatus {
    switch (status) {
        case ServiceStatus.ACTIVE: {
            return {
                status,
                info: 'Aktywny',
                color: 'info'
            };
        }
        case ServiceStatus.PROCESSING: {
            return {
                status,
                info: 'W trakcie integracji',
                color: 'warning'
            };
        }
        case ServiceStatus.CREATING: {
            return {
                status,
                info: 'Nieskonfigurowany',
                color: 'warning'
            };
        }
        case ServiceStatus.INACTIVE: {
            return {
                status,
                info: 'Nieaktywny',
                color: 'error'
            };
        }
        case ServiceStatus.NOT_CREATED: {
            return {
                status,
                info: 'Nieskonfigurowany',
                color: 'error'
            };
        }
        default: {
            return {
                status,
                info: 'Nieskonfigurowany',
                color: 'error'
            };
        }
    }
}
