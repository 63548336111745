import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { CampaignStatus } from '@core/campaigns';

export interface Icons {
    [key: string]: { statusIcon: string; };
}

export interface ActionsMapStatus {
    [key: string]: {
        statusName?: string;
        statusAction: string;
        statusDescription: string;
    };
}

export interface Operation {
    [key: string]: Action;
}

interface Action {
    label: string;
    name: string;
    icon: string;
    iconOnly: boolean;
    easyAccess: boolean;
    fn?: () => void;
}

@Component({
    selector: 'object-actions',
    templateUrl: './object-actions.component.html',
    styleUrls: ['./object-actions.component.scss']
})
export class ObjectActionsComponent implements OnInit {
    @Input()
    public actions?: string[];

    @Input()
    public operations: Operation = {};

    @Input()
    public statusesIcons: Icons = {};

    @Input()
    public actionsMap: ActionsMapStatus = {};

    @Output()
    public onValueChange = new EventEmitter<CampaignStatus>();

    public available: Action[] = [];

    public hiddenActions: Action[] = [];

    public ngOnInit(): void {
        this.actions?.forEach((actionName: string) => {
            let action: Action;

            const status = this.actionsMap[actionName] || false;

            if (status) {
                action = {
                    label: status.statusDescription,
                    name: status.statusAction,
                    icon: status.statusName && this.statusesIcons[status.statusName] ? this.statusesIcons[status.statusName].statusIcon : 'archive',
                    iconOnly: true,
                    easyAccess: true,
                    fn: (): void => {
                        this.changeStatus(status.statusName as CampaignStatus);
                    }
                };

                const customOperation = this.operations[actionName];

                if (customOperation) {
                    action.easyAccess = customOperation.easyAccess;
                    action.icon = customOperation.icon;
                    action.fn = customOperation.fn || action.fn;
                }

                if (action.easyAccess) {
                    this.available.push(action);
                } else {
                    this.hiddenActions.push(action);
                }
            }
        });
    }

    public changeStatus(statusName: CampaignStatus): void {
        this.onValueChange.emit(statusName);
    }
}
