import { Component, Input, OnInit } from '@angular/core';
import { last } from 'rxjs';
import { Bucket, OCDNService, UploadStatus } from '@core/ocdn/ocdn.service';
import { LoggerService } from '@core/logger/logger.service';
import { SnackBarService } from '@shared/interactions/snack-bar/snack-bar.service';
import { ControlValueAccessor, FormControl, NgControl, ValidationErrors } from '@angular/forms';

type ImageRatio = '7:9' | '16:7';

interface FileMetadata {
    name?: string;
    ratio?: ImageRatio;
}

const DEFAULT_MAX_SIZE_KB = 50;

@Component({
    selector: 'video-image-uploader',
    templateUrl: './video-image-uploader.component.html',
    styleUrls: ['./video-image-uploader.component.scss']
})
export class VideoImageUploaderComponent implements ControlValueAccessor, OnInit {
    protected control: FormControl = new FormControl();

    @Input()
    public bucket: Bucket = 'ecommerce';

    @Input()
    public ratio = '7:9';

    @Input()
    public fileTypes = ['PNG', 'JPG', 'GIF'];

    @Input()
    public maxSizeKb = DEFAULT_MAX_SIZE_KB;

    protected fileMetadata: FileMetadata = {};

    protected inputId = `file-upload-${Math.random().toString(36).slice(2, 11)}`;

    protected isLoading = false;

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    private onChange = (url: string): void => { };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onTouched = (): void => { };

    protected onFileDropped($event: DragEvent): void {
        $event.preventDefault();

        if ($event.dataTransfer?.files?.[0]) {
            this.processFile($event.dataTransfer?.files[0]);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected onFileAdd(event: any): void {
        if (event.target.files?.[0]) {
            this.processFile(event.target.files[0]);
        }
    }

    private processFile(file: File): void {
        if (!file.type.match(/^image\//)) {
            this.onFileRemove();
            this.snackBarService.alert('Dozwolone są tylko pliki graficzne.');

            return;
        }

        if (this.maxSizeKb && this.maxSizeKb * 1024 * 1024 < file.size) {
            this.onFileRemove();
            this.snackBarService.alert(`Maksymalny rozmiar pliku to ${this.maxSizeKb} kB`);

            return;
        }

        this.isLoading = true;

        const reader = new FileReader();

        reader.addEventListener('load', async (e) => {
            if (e.target?.result) {
                this.control.setErrors(null);

                const url = URL.createObjectURL(file);

                if (!(await this.parseFile(url, file.name))) {
                    this.onFileRemove();
                    this.snackBarService.alert(`Niepoprawne proporcje obrazu (dozwolone ${this.ratio})`);
                    this.isLoading = false;

                    return;
                }

                if (!this.fileMetadata.ratio) {
                    this.onFileRemove();
                    this.snackBarService.alert('Wystąpił błąd podczas wczytywania pliku');
                    this.isLoading = false;

                    return;
                }

                if (this.control.valid) {
                    this.ocdnService.upload(file, this.bucket).pipe(last()).subscribe({
                        next: res => {
                            if (res.status === UploadStatus.SUCCESS) {
                                this.onChange(res.url as string);
                                this.markAsTouched();
                                this.control.setValue(res.url);
                                this.snackBarService.success('Plik załadowany pomyślnie');
                            }

                            this.isLoading = false;
                        },
                        error: err => {
                            this.logger.error('UPLOAD_FILE_ERROR', err);
                            this.onFileRemove();
                            this.snackBarService.alert('Wystąpił błąd podczas wczytywania pliku');
                            this.isLoading = false;
                        }
                    });
                }
            } else {
                this.onFileRemove();
                this.snackBarService.alert('Wystąpił błąd podczas wczytywania pliku');

                this.isLoading = false;
            }
        });

        reader.readAsDataURL(file);
    }

    private parseFile(url: string, name?: string): Promise<boolean> {
        return new Promise(resolve => {
            const fileName = name || url.split('/').pop();
            const availableRatio = this.ratio === '7:9' ? 7 / 9 : 16 / 7;
            const image = new Image();

            image.src = url;

            image.onload = (): void => {
                const imageWidth = image.width;
                const imageHeight = image.height;

                if (imageWidth / imageHeight !== availableRatio) {
                    return resolve(false);
                }

                switch (imageWidth / imageHeight) {
                    case 7 / 9:
                        this.fileMetadata.ratio = '7:9';
                        this.fileMetadata.name = fileName;

                        break;
                    case 16 / 7:
                        this.fileMetadata.ratio = '16:7';
                        this.fileMetadata.name = fileName;

                        break;
                }

                return resolve(true);
            };
        });
    }

    protected dragOver($event: DragEvent): void {
        $event.preventDefault();
    }

    constructor(
        private controlDirective: NgControl,
        private readonly logger: LoggerService,
        private readonly ocdnService: OCDNService,
        private readonly snackBarService: SnackBarService
    ) {
        this.controlDirective.valueAccessor = this;
    }

    public ngOnInit(): void {
        this.control = this.controlDirective.control as FormControl;

        if (!this.bucket) {
            throw Error('You must pass bucket by input!');
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public markAsTouched(): void {
        this.onTouched();
    }

    public writeValue(val: string): void {
        if (!this.control.value && val) {
            this.control.setValue(val);
            this.parseFile(val);
        }
    }

    private setError(errors: ValidationErrors): void {
        this.control.setErrors({ ...errors, ...this.control.errors });
        this.control.markAsDirty();
        this.control.markAsTouched();
    }

    protected onFileRemove(): void {
        this.onChange('');
        this.markAsTouched();
        this.control.setValue('');
        this.fileMetadata = {};
    }
}
