import { Component } from '@angular/core';

@Component({
    selector: 'app-menu-panel-content',
    templateUrl: './menu-panel-content.component.html',
    styleUrls: ['./menu-panel-content.component.scss']
})
export class MenuPanelContentComponent {

}
