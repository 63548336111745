import { Injectable } from '@angular/core';
import { GraphqlService } from '@core/graphql/graphql.service';
import { Endpoint } from '@core/graphql/models/enums/endpoint.enum';
import { GraphqlPaginatedResponse } from '@core/graphql/models/graphql-paginated-response.model';
import { EMPTY, Observable, expand, lastValueFrom, map, reduce } from 'rxjs';
import resultModelGql from '@core/graphql/query-models/result.model.graphql';
import serviceGqlModel from './graphql/models/service.model.graphql';
import websiteGqlModel from './graphql/models/website.model.graphql';
import getWebsiteGqlQuery from './graphql/queries/get-website.query.graphql';
import getWebsiteByIdGqlQuery from './graphql/queries/get-website-by-id.query.graphql';
import getWebsitesGqlQuery from './graphql/queries/get-websites.query.graphql';
import createWebsiteGqlMutation from './graphql/mutations/create-website.mutation.graphql';
import updateWebsiteGqlMutation from './graphql/mutations/update-website.mutation.graphql';

import { WebsiteInterface } from './models/website.model';
import { GraphqlMutationResponse } from '../graphql/models/graphql-mutation-response.model';

export type WebsiteFullInterface = WebsiteInterface;
export type WebsiteBaseInterface = Omit<WebsiteInterface, 'services'>;

export type WebsiteUpdateInterface = Pick<WebsiteInterface, 'id' | 'contractorId'> & Partial<WebsiteInterface>;
export type WebsiteCreateInterface = Pick<WebsiteInterface, 'name' | 'logo' | 'contractorId' | 'contractorContactId'> & Partial<WebsiteInterface>;

const LOAD_LIMIT = 100;

@Injectable({
    providedIn: 'root'
})
export class WebsiteService {
    constructor(
        private readonly graphqlService: GraphqlService
    ) { }

    public getWebsites(contractorId: number): Observable<WebsiteBaseInterface[]> {
        return this.getWebsitesBatch(contractorId).pipe(
            expand(response => response[0].length >= LOAD_LIMIT ? this.getWebsitesBatch(contractorId, response[1]) : EMPTY),
            map(response => response[0]),
            reduce((acc: WebsiteBaseInterface[], current: WebsiteBaseInterface[]) => acc.concat(current), []),
            map(websites => [...new Map(websites.map(item => [item.id, item])).values()]));
    }

    public getWebsitesBatch(contractorId: number, offset = 0): Observable<[WebsiteBaseInterface[], number]> {
        const query = getWebsitesGqlQuery + websiteGqlModel;

        return this.graphqlService.query<{ contractor: { websites: GraphqlPaginatedResponse<WebsiteBaseInterface>; }; }>(
            Endpoint.GatewayApi,
            query,
            { contractorId, limit: LOAD_LIMIT, offset }
        ).pipe(
            map(res => [res.contractor.websites.items, offset + res.contractor.websites.items.length])
        );
    }

    public getWebsite(contractorId: number, websiteId: number): Observable<WebsiteFullInterface> {
        const query = getWebsiteGqlQuery + websiteGqlModel + serviceGqlModel;

        return this.graphqlService.query<{ contractor: { website: WebsiteFullInterface; }; }>(
            Endpoint.GatewayApi,
            query,
            { websiteId, contractorId }
        ).pipe(map(response => response.contractor.website));
    }

    public getWebsiteById(websiteId: number): Observable<{ id: number; contractorId: number; }> {
        const query = getWebsiteByIdGqlQuery;

        return this.graphqlService.query<{ website: { id: number; contractorId: number; }; }>(
            Endpoint.GatewayApi,
            query,
            { websiteId }
        ).pipe(map(response => response.website));
    }

    public createWebsite(data: WebsiteCreateInterface): Promise<{ id: number; } | undefined> {
        const query = createWebsiteGqlMutation + resultModelGql;

        return lastValueFrom(this.graphqlService.mutation<{
            contractor: {
                website: {
                    create: GraphqlMutationResponse<{ id: number; }>;
                };
            };
        }, WebsiteCreateInterface>(Endpoint.GatewayApi, query, data).pipe(
            map(res => res.contractor.website.create.data)
        ));
    }

    public updateWebsite(data: WebsiteUpdateInterface): Promise<{ status: boolean; }> {
        const query = updateWebsiteGqlMutation;

        return lastValueFrom(this.graphqlService.mutation<{
            contractor: {
                website: {
                    update: {
                        status: boolean;
                    };
                };
            };
        }>(Endpoint.GatewayApi, query, data).pipe(
            map(res => res.contractor.website.update)
        ));
    }
}
