import { NativeDateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export class CustomDateAdapter extends NativeDateAdapter {
    public override format(date: Date): string {
        return dayjs(date).format('DD.MM.YYYY');
    }

    public override parse(value: string): Date | null {
        return dayjs(value, 'DD.MM.YYYY').toDate();
    }
}
