import {
    Component,
    computed,
    effect,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    signal,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {
    DateRange,
    DefaultMatCalendarRangeStrategy,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
    MatCalendar
} from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormControl, FormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { PeriodPickerCalendarHeaderComponent } from './components/period-picker-calendar-header';
import { parsePreset } from './parse-preset.function';
import { PeriodPreset, PeriodPresetEnum } from './period-picker.interface';

import 'dayjs/locale/pl';

@Component({
    selector: 'period-picker',
    templateUrl: './period-picker.component.html',
    styleUrl: './period-picker.component.scss',
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy
        }
    ]
})
export class PeriodPickerComponent implements OnDestroy, OnInit {
    @ViewChildren(MatCalendar) protected calendars!: QueryList<MatCalendar<Date>>;

    @ViewChild('calendarTrigger') protected calendarTrigger!: MatMenuTrigger;

    protected customCalendarHeader = PeriodPickerCalendarHeaderComponent;

    @Input()
    public form!: FormGroup<{ from: FormControl<string>; to: FormControl<string>; }>;

    protected selected: DateRange<Date> | null = null;

    protected currentMonth = signal(dayjs().startOf('month').toDate());

    protected previousMonth = computed(() => dayjs(this.currentMonth()).subtract(1, 'month').toDate());

    protected currentMonthLabel = computed(() => dayjs(this.currentMonth()).locale('pl').format('MMMM YYYY'));

    protected previousMonthLabel = computed(() => dayjs(this.previousMonth()).locale('pl').format('MMMM YYYY'));

    protected currentRange = signal<DateRange<Date> | null>(null);

    protected maxDate = new Date();

    protected minDate = dayjs().startOf('day').utc().subtract(90, 'days').toDate();

    protected valueText = signal('');

    constructor() {
        effect(() => {
            this.calendars.forEach((calendar, index) => {
                calendar.activeDate = index === 1 ? this.currentMonth() : this.previousMonth();
            });
        });
    }

    protected selectedChange(selectedDate: Date | null): void {
        if (!this.selected?.start || this.selected?.end) {
            this.selected = new DateRange<Date>(selectedDate, null);
        } else {
            const start = this.selected.start;
            const end = selectedDate;

            if (end && end < start) {
                this.selected = new DateRange<Date>(end, start);
            } else {
                this.selected = new DateRange<Date>(start, end);
            }
        }
    }

    protected previousClicked(): void {
        this.currentMonth.update((value) => {
            if (dayjs(value).subtract(2, 'month').isBefore(this.minDate, 'month')) {
                return value;
            }

            return dayjs(value).subtract(1, 'month').toDate();
        });
    }

    protected nextClicked(): void {
        this.currentMonth.update((value) => {
            const nextMonth = dayjs(value).add(1, 'month');

            return nextMonth.isAfter(this.maxDate, 'month') ? value : dayjs(value).add(1, 'month').toDate();
        });
    }

    protected apply(): void {
        this.form.setValue({
            from: dayjs(this.selected?.start).startOf('day').toISOString(),
            to: dayjs(this.selected?.end ?? this.selected?.start)
                .endOf('day')
                .toISOString()
        });

        this.calendarTrigger.closeMenu();
    }

    private resetSelection(): void {
        this.selected = new DateRange<Date>(
            dayjs(this.form.controls.from.value).toDate(),
            dayjs(this.form.controls.to.value).toDate()
        );
    }

    protected cancel(): void {
        this.resetSelection();

        this.calendarTrigger.closeMenu();
    }

    private formSubscription: Subscription | null = null;

    private setValueText(from: Date, to: Date): void {
        const preset = parsePreset(from, to);

        if (preset) {
            return this.valueText.set(preset.label);
        }

        if (dayjs(from).isSame(to, 'day')) {
            return this.valueText.set(dayjs(from).format('DD.MM.YYYY'));
        }

        this.valueText.set(
            `${ dayjs(from).format('DD.MM.YYYY') } - ${ dayjs(to).format('DD.MM.YYYY') }`
        );
    }

    private setCurrentRange(from: Date, to: Date): void {
        this.currentRange.set(new DateRange<Date>(from, to));
    }

    public ngOnInit(): void {
        this.formSubscription = this.form.valueChanges.subscribe((period) => {
            if (!period.from || !period.to) {
                return;
            }

            this.setValueText(new Date(period.from), new Date(period.to));
            this.setCurrentRange(new Date(period.from), new Date(period.to));
        });

        this.resetSelection();

        this.setValueText(new Date(this.form.controls.from.value), new Date(this.form.controls.to.value));
        this.setCurrentRange(new Date(this.form.controls.from.value), new Date(this.form.controls.to.value));
    }

    public ngOnDestroy(): void {
        this.formSubscription?.unsubscribe();
    }

    protected onCalendarClosed(): void {
        setTimeout(() => {
            this.resetSelection();
            this.resetCalendars();
        }, 200);
    }

    protected handlePresetSelected(preset: PeriodPreset): void {
        let to = dayjs().endOf('day');

        let from: dayjs.Dayjs | null;

        switch (preset.name) {
            case PeriodPresetEnum.LAST_3_MONTHS:
                from = to.subtract(3, 'month').startOf('day');

                break;
            case PeriodPresetEnum.LAST_30_DAYS:
                from = to.subtract(30, 'day').startOf('day');

                break;
            case PeriodPresetEnum.LAST_7_DAYS:
                from = to.subtract(6, 'day').startOf('day');

                break;
            case PeriodPresetEnum.LAST_24_HOURS:
                from = dayjs().subtract(24, 'hour').startOf('hour');
                to = dayjs().endOf('hour');

                break;
        }

        this.selected = new DateRange<Date>(from.toDate(), to.toDate());
        this.form.setValue({ from: from.toISOString(), to: to.toISOString() });
        this.calendarTrigger.closeMenu();
    }

    private resetCalendars(): void {
        this.currentMonth.set(
            dayjs(this.maxDate).get('month') === dayjs(this.selected?.start).get('month')
                ? dayjs(this.selected?.start).startOf('month').toDate()
                : dayjs(this.selected?.start).startOf('month').add(1, 'month').toDate()
        );
    }

    protected dateClass(): () => string {
        return () => 'period-picker-date';
    }
}
