import { Component, Input } from '@angular/core';
import { SKAPIEC_AGREEMENT_URL } from '@skapiec/consts/skapiec-agreement.const';
import { ServiceType } from '../../../core/services/models/service.interface';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
    @Input()
    public serviceType!: ServiceType;

    protected ServiceType = ServiceType;

    protected SKAPIEC_AGREEMENT_URL = SKAPIEC_AGREEMENT_URL;
}
