import { Injectable } from '@angular/core';
import { RoleTypeEntity } from '@core/contractors';
import { FeedService } from '@core/feed/feed.service';
import {
    FeedEditableInterface,
    FeedEditableUpdateInterface,
    FeedInterface,
    FeedRemoveInterface,
    FeedStatus
} from '@core/feed/models/feed.interface';
import { MeService } from '@core/me';
import { ServiceFullStatus, ServiceInterface, ServiceStatus, ServiceType } from '@core/services/models/service.interface';
import { Memomize } from '@helpers/decorators';
import { getFeedStatus } from '../../../../projects/feed/src/app/helpers';
import { getPixelStatus } from '../../../../projects/pixel/src/app/helpers/pixel-status-mapper';
import { ServiceService } from '../services/service.service';
import { ContractorContextService } from './contractor-context.service';
import {
    WebsiteBaseInterface,
    WebsiteCreateInterface,
    WebsiteFullInterface,
    WebsiteService,
    WebsiteUpdateInterface
} from '../website/website.service';
import {
    BehaviorSubject,
    Observable,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    of,
    shareReplay,
    switchMap,
    tap, firstValueFrom
} from 'rxjs';
import { MessageException } from '@helpers/exceptions/message.exception';
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root'
})
export class WebsiteContextService {
    private selectedWebsiteId$ = new BehaviorSubject<number>(this.storedId);

    private selectedWebsiteReload$ = new BehaviorSubject(true);

    private selectedWebsiteLoading$ = new BehaviorSubject(true);

    private websitesReload$ = new BehaviorSubject(null);

    private websitesLoading$ = new BehaviorSubject(true);

    @Memomize()
    public getWebsites(): Observable<WebsiteBaseInterface[]> {
        return combineLatest([
            // We only want to know when contractor id has changed
            this.contractorContextService.getSelectedContractor().pipe(
                map(contractor => contractor?.id ?? null),
                distinctUntilChanged()
            ),
            this.websitesReload$
        ]).pipe(
            tap(() => {
                // We'll mark loading status
                this.websitesLoading$.next(true);
            }),
            switchMap(([id]) => id ? this.websiteService.getWebsites(id) : of([])),
            tap(websites => {
                // If websites list is changed and on the new list the is no current selected website
                // we'll select the first one from available list
                if (websites.length && !websites.find(website => website.id === this.selectedWebsiteId$.value)) {
                    this.selectedWebsiteId$.next(websites[0].id);
                }

                this.websitesLoading$.next(false);
            }),
            shareReplay(1)
        );
    }

    @Memomize()
    public getWebsitesLoadingStatus(): Observable<boolean> {
        return combineLatest([
            this.websitesLoading$,
            this.contractorContextService.getSelectedContractorLoadingStatus()
        ]).pipe(
            map(([websitesLoading, contractorLoading]) => websitesLoading || contractorLoading)
        ).pipe(
            distinctUntilChanged(),
            shareReplay()
        );
    }

    public getSelectedWebsite(): Observable<WebsiteFullInterface>;

    public getSelectedWebsite(required: true): Observable<WebsiteFullInterface>;

    public getSelectedWebsite(required: false): Observable<WebsiteFullInterface | null>;

    @Memomize()
    public getSelectedWebsite(required = true): Observable<WebsiteFullInterface | null> {
        return combineLatest([
            this.getWebsites(),
            this.selectedWebsiteId$
        ]).pipe(
            // First we try to find current selected website in websites list. If it not exists we'll return null.
            map(([websites, websiteId]) => websites.find(item => item.id === websiteId) ?? null),

            // in case when user has no websites we cannot wait for load selected one
            tap(website => {
                if (website === null) {
                    this.selectedWebsiteLoading$.next(false);
                }
            }),

            // We pass throught stream only if website is not null
            filter(website => required === false || website !== null),

            // In this place we know tha we should store selected website id so we do it.
            tap((website) => {
                if (website) {
                    this.storedId = website.id;
                }

                this.selectedWebsiteLoading$.next(true);
            }),

            // Next we fetch full website object from API
            switchMap(website => {
                // There we add posibility to reload selected website
                if (website === null) {
                    return of(null);
                }

                return this.selectedWebsiteReload$.pipe(
                    switchMap(() => this.websiteService.getWebsite(website.contractorId, website.id))
                );
            }),

            // And finaly set website loading status to false
            tap(() => this.selectedWebsiteLoading$.next(false)),

            // send selected website id and name to Sentry
            tap(website => {
                Sentry.setTags({
                    websiteId: website?.id || null,
                    websiteName: website?.name || null
                });
            })
        ).pipe(
            shareReplay(1)
        );
    }

    @Memomize()
    public getSelectedWebsiteFeeds(withReports = false): Observable<FeedInterface[]> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.feedService.getFeeds(website.contractorId, website.id, withReports);
                }

                return of([]);
            })
        );
    }

    @Memomize()
    public getSelectedWebsiteFeedService(): Observable<ServiceInterface | null> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getService(website.contractorId, website.id, ServiceType.FEED);
                }

                return of(null);
            })
        );
    }

    @Memomize()
    public getSelectedWebsiteFeedStatus(): Observable<ServiceStatus> {
        return combineLatest([this.getSelectedWebsiteFeedService(), this.getSelectedWebsiteFeeds()]).pipe(
            map(([service, feeds]) => feeds.length
                ? (service?.status === ServiceStatus.ACTIVE
                    ? feeds.every(feed => feed.status !== FeedStatus.ACTIVE) ? ServiceStatus.PROCESSING : ServiceStatus.ACTIVE
                    : service?.status ?? ServiceStatus.NOT_CREATED)
                : ServiceStatus.NOT_CREATED
            )
        );
    }

    @Memomize()
    public getSelectedWebsiteServices(): Observable<ServiceInterface[]> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getServices(website.contractorId, website.id);
                }

                return of([]);
            })
        );
    }

    @Memomize()
    public getSelectedWebsitePixelService(): Observable<ServiceInterface | null> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getService(website.contractorId, website.id, ServiceType.PIXEL);
                }

                return of(null);
            })
        );
    }

    @Memomize()
    public getSelectedWebsitePixelStatus(): Observable<ServiceStatus> {
        return this.getSelectedWebsitePixelService().pipe(
            map(service => service?.status ?? ServiceStatus.NOT_CREATED)
        );
    }

    @Memomize()
    public getSelectedWebsiteSkapiecService(): Observable<ServiceInterface | null> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getService(website.contractorId, website.id, ServiceType.SKAPIEC);
                }

                return of(null);
            })
        );
    }

    @Memomize()
    public getSelectedWebsiteSkapiecStatus(): Observable<ServiceStatus> {
        return this.getSelectedWebsiteSkapiecService().pipe(
            map(service => service?.status ?? ServiceStatus.NOT_CREATED)
        );
    }

    @Memomize()
    public getSelectedWebsiteRetailMediaService(): Observable<ServiceInterface | null> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getService(website.contractorId, website.id, ServiceType.RETAIL_MEDIA);
                }

                return of(null);
            })
        );
    }

    @Memomize()
    public getSelectedWebsiteRetailMediaStatus(): Observable<ServiceStatus> {
        return this.getSelectedWebsiteRetailMediaService().pipe(
            map(service => service?.status ?? ServiceStatus.NOT_CREATED)
        );
    }

    @Memomize()
    public getSelectedWebsiteRetailMediaPartnerService(): Observable<ServiceInterface | null> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getService(website.contractorId, website.id, ServiceType.RETAIL_MEDIA_PARTNER);
                }

                return of(null);
            })
        );
    }

    @Memomize()
    public getSelectedWebsiteRetailMediaPartnerStatus(): Observable<ServiceStatus> {
        return this.getSelectedWebsiteRetailMediaPartnerService().pipe(
            map(service => service?.status ?? ServiceStatus.NOT_CREATED)
        );
    }

    @Memomize()
    public getSelectedWebsiteLamodaService(): Observable<ServiceInterface | null> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getService(website.contractorId, website.id, ServiceType.LAMODA);
                }

                return of(null);
            })
        );
    }

    @Memomize()
    public getSelectedWebsiteLamodaStatus(): Observable<ServiceStatus> {
        return this.getSelectedWebsiteLamodaService().pipe(
            map(service => service?.status ?? ServiceStatus.NOT_CREATED)
        );
    }

    @Memomize()
    public getSelectedWebsiteOpineoService(): Observable<ServiceInterface | null> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getService(website.contractorId, website.id, ServiceType.OPINEO);
                }

                return of(null);
            })
        );
    }

    @Memomize()
    public getSelectedWebsiteOpineoStatus(): Observable<ServiceStatus> {
        return this.getSelectedWebsiteOpineoService().pipe(
            map(service => service?.status ?? ServiceStatus.NOT_CREATED)
        );
    }

    @Memomize()
    public getSelectedWebsiteVideoAdsService(): Observable<ServiceInterface | null> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getService(website.contractorId, website.id, ServiceType.VIDEO_ADS);
                }

                return of(null);
            })
        );
    }

    @Memomize()
    public getSelectedWebsiteVideoAdsStatus(): Observable<ServiceStatus> {
        return this.getSelectedWebsiteVideoAdsService().pipe(
            map(service => service?.status ?? ServiceStatus.NOT_CREATED)
        );
    }

    @Memomize()
    public getSelectedWebsitePerformanceEasyService(): Observable<ServiceInterface | null> {
        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getService(website.contractorId, website.id, ServiceType.PERFORMANCE_EASY);
                }

                return of(null);
            })
        );
    }

    @Memomize()
    public getSelectedWebsitePerformanceEasyStatus(): Observable<ServiceStatus> {
        return this.getSelectedWebsitePerformanceEasyService().pipe(
            map(service => service?.status ?? ServiceStatus.NOT_CREATED)
        );
    }

    @Memomize()
    public getSelectedWebsiteServiceStatus(service: ServiceType): Observable<ServiceFullStatus> {
        if (service === ServiceType.FEED) {
            return this.getSelectedWebsiteFeedStatus().pipe(
                map(status => this.mapStatusToFull(service, status))
            );
        }

        return this.getSelectedWebsite(false).pipe(
            switchMap(website => {
                if (website) {
                    return this.serviceService.getService(website.contractorId, website.id, service);
                }

                return of(null);
            }),
            map(service => service?.status ?? ServiceStatus.NOT_CREATED),
            map(status => this.mapStatusToFull(service, status))
        );
    }

    public getSelectedWebsiteOperators(): Observable<{ id: number; email: string; }[]> {
        return combineLatest([
            this.getSelectedWebsite(false),
            this.meService.getPermissions().pipe(
                switchMap(perms => perms.isCustomerServiceAdministrator
                    ? this.contractorContextService.getSelectedContractorPermissions()
                    : of([])
                )
            )
        ]).pipe(
            map(([website, permissions]) => {
                return permissions.filter(permission => {
                    if (permission.resourceType === RoleTypeEntity.CONTRACTOR_ADMIN) {
                        return true;
                    }

                    if (permission.resourceType === RoleTypeEntity.CONTRACTOR_OWNER) {
                        return true;
                    }

                    if (permission.resourceType === RoleTypeEntity.WEBSITE_OPERATOR && website && permission.resourceId === `${website.contractorId}|${website.id}`) {
                        return true;
                    }

                    return false;
                }).map(permission => permission.account);
            })
        );
    }

    private mapStatusToFull(service: ServiceType, status: ServiceStatus): ServiceFullStatus {
        switch (service) {
            case ServiceType.FEED: {
                return getFeedStatus(status);
            }
            case ServiceType.PIXEL: {
                return getPixelStatus(status);
            }
            default: {
                return {
                    status,
                    info: status,
                    color: 'default'
                };
            }
        }
    }

    @Memomize()
    public getSelectedWebsiteLoadingStatus(): Observable<boolean> {
        return combineLatest([
            this.selectedWebsiteLoading$,
            this.getWebsitesLoadingStatus()
        ]).pipe(
            map(([websiteLoading, websitesLoading]) => websiteLoading || websitesLoading)
        ).pipe(
            distinctUntilChanged(),
            shareReplay(1)
        );
    }

    public changeWebsite(websiteId: number): void {
        if (this.selectedWebsiteId$.value !== websiteId) {
            this.selectedWebsiteId$.next(websiteId);
        }
    }

    public reloadWebsites(): void {
        this.websitesReload$.next(null);
    }

    public async createWebsite(website: WebsiteCreateInterface): Promise<number> {
        const res = await this.websiteService.createWebsite(website);
        const newId = res?.id;

        if (newId) {
            this.changeWebsite(newId);
            this.reloadWebsites();
        } else {
            throw Error('Website not created!');
        }

        return newId;
    }

    public async updateWebsite(website: WebsiteUpdateInterface): Promise<boolean> {
        const result = await this.websiteService.updateWebsite(website);

        if (result.status) {
            this.reloadWebsites();

            return result.status;
        } else {
            throw Error('Website not updated!');
        }
    }

    public async createFeed(feed: FeedEditableInterface): Promise<number> {
        const feedServices = await firstValueFrom(this.getSelectedWebsiteFeedService());
        const websiteId = this.selectedWebsiteId$.value;
        const contractorId = (await firstValueFrom(this.contractorContextService.getSelectedContractor()))?.id;

        if (!contractorId || !websiteId) {
            throw new MessageException('Nie udało się pobrać informacji o kontrahencie lub stronie. Proszę spróbować później.');
        }

        if (!feedServices) {
            await this.feedService.createFeedService({ websiteId, contractorId });
        }

        const id = await this.feedService.createFeed({ ...feed, websiteId, contractorId });

        if (id && websiteId === this.storedId) {
            this.selectedWebsiteReload$.next(true);
        }

        return id;
    }

    public async updateFeed(feed: FeedEditableUpdateInterface): Promise<boolean> {
        const websiteId = this.selectedWebsiteId$.value;
        const contractorId = (await firstValueFrom(this.contractorContextService.getSelectedContractor()))?.id;

        if (!contractorId || !websiteId) {
            throw new MessageException('Nie udało się pobrać informacji o kontrahencie lub stronie. Proszę spróbować później.');
        }

        const status = await this.feedService.updateFeed({ ...feed, websiteId, contractorId });

        if (status && websiteId === this.storedId) {
            this.selectedWebsiteReload$.next(true);
        }

        return status;
    }

    public async removeFeed(feed: FeedRemoveInterface): Promise<boolean> {
        const status = await this.feedService.removeFeed(feed);

        if (status && feed.websiteId === this.storedId) {
            this.selectedWebsiteReload$.next(true);
        }

        return status;
    }

    constructor(
        private readonly feedService: FeedService,
        private readonly websiteService: WebsiteService,
        private readonly contractorContextService: ContractorContextService,
        private readonly serviceService: ServiceService,
        private readonly meService: MeService
    ) { }

    private get storedId(): number {
        return Number(localStorage.getItem('storedSelectedWebsiteId'));
    }

    private set storedId(id: number) {
        Number(localStorage.setItem('storedSelectedWebsiteId', String(id)));
    }
}
