import { Injectable } from '@angular/core';
import { GraphqlService } from '@core/graphql/graphql.service';
import { Endpoint } from '@core/graphql/models/enums/endpoint.enum';
import { PermissionInterface, RoleType } from './models/permission.model';
import { ContractorInterface, ContractorContactInterface } from '@ecommerce/lib-interfaces';
import { GraphqlPaginatedResponse } from '@core/graphql/models/graphql-paginated-response.model';
import { catchError, lastValueFrom, map, Observable, of, Subject, switchMap } from 'rxjs';
import { AsyncJobRequest, AsyncJobResponse, AsyncJobsService } from '../async-jobs';
import { AsyncJobResult } from '../async-jobs/interfaces/async-job-result.interface';

import resultModel from '@core/graphql/query-models/result.model.graphql';
import asyncJobGqlModel from '@core/async-jobs/query-models/async-job.model.graphql';
import contractorGqlModel from './graphql/models/contractor.model.graphql';
import contractorListGqlModel from './graphql/models/contractor-list.model.graphql';
import createContractorGqlMutation from './graphql/mutations/create-contractor.mutation.graphql';
import updateContractorGqlMutation from './graphql/mutations/update-contractor.mutation.graphql';
import createContractorContactGqlMutation from './graphql/mutations/create-contractor-contact.mutation.graphql';
import updateContractorContactGqlMutation from './graphql/mutations/update-contractor-contact.mutation.graphql';
import createInvitationGqlMutation from './graphql/mutations/invitation-create.mutation.graphql';
import acceptInvitationGqlMutation from './graphql/mutations/invitation-accept.mutation.graphql';
import createPermissionGqlMutation from './graphql/mutations/permission-create.mutation.graphql';
import deletePermissionGqlMutation from './graphql/mutations/permission-delete.mutation.graphql';
import getContractorGqlQuery from './graphql/queries/get-contractor.query.graphql';
import getContractorContactsGqlQuery from './graphql/queries/get-contractor-contacts.query.graphql';
import getContractorPermissionsGqlQuery from './graphql/queries/get-contractor-permissions.query.graphql';
import getInvitationGqlQuery from './graphql/queries/get-invitation.query.graphql';
import getContractorsGqlQuery from './graphql/queries/get-contractors.query.graphql';
import contractorExistsGqlQuery from './graphql/queries/contractor-exists.query.graphql';
import { GraphqlMutationResponse } from '@core/graphql/models/graphql-mutation-response.model';
import { ContractorAccessInterface } from './models/contractor.model';

const LOAD_LIMIT = 1000; // cannot be higher because of the API limit

export type GetContractorsInterface = Pick<ContractorInterface, 'id' | 'name' | 'status'>;
export type GetContractorInterface<T = unknown> = Pick<ContractorInterface,
    'id'
    | 'nip'
    | 'regon'
    | 'name'
    | 'email'
    | 'status'
    | 'country'
    | 'address'
    | 'postalCode'
    | 'city'
    | 'blacklist'
> & T & { access: ContractorAccessInterface; };

export type UpdateContractorInterface = Pick<ContractorInterface, 'id'> & Partial<ContractorInterface>;
export type CreateContractorInterface = Pick<ContractorInterface, 'name' | 'nip' | 'regon' | 'country' | 'address' | 'city' | 'postalCode'>;
export type UpdateContractorContactInterface = Pick<ContractorContactInterface, 'id'> & Partial<ContractorContactInterface>;
export type CreateContractorContactInterface = Pick<ContractorContactInterface,
    'email'
    | 'name'
    | 'surname'
    | 'phoneNumber'
    | 'position'
    | 'crmId'
    | 'contractorId'
>;

export interface DeletePermission {
    id: number;
    contractorId: number;
}

export interface CreatePermission {
    role: RoleType;
    accountId: number;
    resourceIds?: string[];
    contractorId: number;
}

export interface CreateInvitation extends Omit<CreatePermission, 'accountId'> {
    email: string;
}

@Injectable({
    providedIn: 'root'
})
export class ContractorService {
    constructor(
        private readonly graphqlService: GraphqlService,
        private readonly asyncJobsService: AsyncJobsService
    ) { }

    // Someday we should refactor contractor select component and fetch only part of data (not all) but... someday not now ;)
    public getContractors(firstContractorId?: number): Observable<GetContractorsInterface[]> {
        const items: GetContractorsInterface[] = [];
        const subject = new Subject<GetContractorsInterface[]>();

        const fetch = async (offset = 0): Promise<void> => {
            const batch = await lastValueFrom(this.getContractorsBatch(offset));

            if (batch.length) {
                items.push(...(firstContractorId ? batch.filter(item => item.id !== firstContractorId) : batch));

                if (batch.length === LOAD_LIMIT) {
                    await fetch(offset + LOAD_LIMIT);
                } else {
                    subject.next([...items]);
                }
            } else {
                subject.next([...items]);
            }
        };

        (async (): Promise<void> => {
            if (firstContractorId) {
                const batch = await lastValueFrom(
                    this.getContractorsBatch(0, LOAD_LIMIT, [{ field: 'id', operator: 'eq', value: firstContractorId }])
                );

                if (batch.length) {
                    items.push(...batch);
                    subject.next([...items]);
                }
            }

            await fetch();
        })();

        return subject;
    }

    public getContractor(contractorId: number): Observable<GetContractorInterface | null> {
        const query = getContractorGqlQuery + contractorGqlModel;

        return this.graphqlService.query<{
            contractor: GetContractorInterface;
        }>(Endpoint.GatewayApi, query, { id: contractorId }).pipe(
            catchError((err) => {
                if (err.message === 'Forbidden') {
                    return of(null);
                }

                throw err;
            }),
            map(res => res?.contractor ?? null)
        );
    }

    public contractorExists(nip: string): Observable<boolean> {
        const query = contractorExistsGqlQuery;

        return this.graphqlService.query<{ contractorExists: boolean; }>(Endpoint.GatewayApi, query, { nip }).pipe(
            map(res => res.contractorExists),
            catchError(() => {
                return of(false);
            })
        );
    }

    public getFirstContractor(): Observable<GetContractorInterface | null> {
        return this.getContractorsBatch(0, 1).pipe(
            switchMap(items => items.length ? this.getContractor(items[0].id) : of(null))
        );
    }

    public getContractorContacts(contractorId: number): Observable<ContractorContactInterface[]> {
        const query = getContractorContactsGqlQuery;

        return this.graphqlService.query<{
            contractor: { contacts: { items: ContractorContactInterface[]; }; };
        }>(Endpoint.GatewayApi, query, { id: contractorId }).pipe(
            map(res => res.contractor.contacts.items)
        );
    }

    public getContractorPermissions(contractorId: number): Observable<PermissionInterface[]> {
        const query = getContractorPermissionsGqlQuery;

        return this.graphqlService.query<{
            contractor: { permissions: { items: PermissionInterface[]; }; };
        }>(Endpoint.GatewayApi, query, { id: contractorId }).pipe(
            map(res => res.contractor.permissions.items)
        );
    }

    public getContractorAccounts(contractorId: number): Observable<PermissionInterface['account'][]> {
        return this.getContractorPermissions(contractorId).pipe(map(res => {
            const result = new Map<string, PermissionInterface['account']>;

            res.forEach(item => {
                if (result.has(item.account.email) === false) {
                    result.set(item.account.email, item.account);
                }
            });

            return [...result.values()];
        }));
    }

    public createContractor(contractor: CreateContractorInterface): Promise<GraphqlMutationResponse<{ id: number; }>> {
        const query = createContractorGqlMutation + resultModel;

        return lastValueFrom(this.graphqlService.mutation<{
            contractor: { create: GraphqlMutationResponse<{ id: number; }>; };
        }, CreateContractorInterface>(Endpoint.GatewayApi, query, contractor).pipe(
            map(res => res.contractor.create)
        ));
    }

    public updateContractor(contractor: UpdateContractorInterface): Promise<GraphqlMutationResponse<never>> {
        const query = updateContractorGqlMutation + resultModel;

        return lastValueFrom(this.graphqlService.mutation<{
            contractor: { update: GraphqlMutationResponse<never>; };
        }, UpdateContractorInterface>(Endpoint.GatewayApi, query, contractor).pipe(
            map(res => res.contractor.update)
        ));
    }

    public createContractorContact(contact: CreateContractorContactInterface): Promise<GraphqlMutationResponse<{ id: number; }>> {
        const query = createContractorContactGqlMutation + resultModel;

        return lastValueFrom(this.graphqlService.mutation<{
            contractor: { contact: { create: GraphqlMutationResponse<{ id: number; }>; }; };
        }, CreateContractorContactInterface>(Endpoint.GatewayApi, query, contact).pipe(
            map(res => res.contractor.contact.create)
        ));
    }

    public updateContractorContact(contact: UpdateContractorContactInterface): Promise<GraphqlMutationResponse<never>> {
        const query = updateContractorContactGqlMutation + resultModel;

        return lastValueFrom(this.graphqlService.mutation<{
            contractor: { contact: { update: GraphqlMutationResponse<never>; }; };
        }, UpdateContractorContactInterface>(Endpoint.GatewayApi, query, contact).pipe(
            map(res => res.contractor.contact.update)
        ));
    }

    public createPermission(params: CreatePermission): Promise<GraphqlMutationResponse<{ ids: number[]; }>> {
        const query = this.graphqlService.mutation<{
            contractor: { permission: { create: GraphqlMutationResponse<{ ids: number[]; }>; }; };
        }, CreatePermission>(Endpoint.GatewayApi, createPermissionGqlMutation, params).pipe(
            map(res => res.contractor.permission.create)
        );

        return lastValueFrom(query);
    }

    public deletePermission(params: DeletePermission): Promise<GraphqlMutationResponse<never>> {
        const query = this.graphqlService.mutation<{
            contractor: { permission: { delete: GraphqlMutationResponse<never>; }; };
        }, DeletePermission>(Endpoint.GatewayApi, deletePermissionGqlMutation, params).pipe(
            map(res => res.contractor.permission.delete)
        );

        return lastValueFrom(query);
    }

    public createInvitation(params: CreateInvitation): Promise<boolean> {
        const query = this.graphqlService.mutation<{
            contractor: { invite: boolean; };
        }, CreateInvitation>(Endpoint.GatewayApi, createInvitationGqlMutation, params).pipe(
            map(res => res.contractor.invite)
        );

        return lastValueFrom(query);
    }

    public getInvitation(params: CreateInvitation): Promise<string> {
        const query = this.graphqlService.query<{
            contractor: { getInvitation: string; };
        }, CreateInvitation>(Endpoint.GatewayApi, getInvitationGqlQuery, params).pipe(
            map(res => res.contractor.getInvitation)
        );

        return lastValueFrom(query);
    }

    public acceptInvitation(token: string): Promise<AsyncJobResponse<AsyncJobResult<undefined>>> {
        const query = acceptInvitationGqlMutation + asyncJobGqlModel;

        return this.asyncJobsService.fetch<AsyncJobResult<undefined>>(
            this.graphqlService.mutation<{ contractor: { acceptInvitation: AsyncJobRequest; }; }, { token: string; }>(
                Endpoint.GatewayApi,
                query,
                { token }
            ).pipe(
                map(res => res.contractor.acceptInvitation)
            )
        );
    }

    private getContractorsBatch(offset = 0, limit = LOAD_LIMIT, filters?: unknown[]): Observable<GetContractorsInterface[]> {
        const query = getContractorsGqlQuery + contractorListGqlModel;

        return this.graphqlService.query<{
            contractors: GraphqlPaginatedResponse<GetContractorsInterface>;
        }>(Endpoint.GatewayApi, query, { limit, offset, filters }).pipe(
            map(res => res.contractors.items)
        );
    }
}
