import { ChangeDetectionStrategy, Component, effect, input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

export interface DropdownButtonItem {
    value: string | number;
    label: string;
    description?: string;
    recommended?: boolean;
}

@Component({
    selector: 'dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrl: './dropdown-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownButtonComponent implements ControlValueAccessor, OnInit {
    protected control: FormControl = new FormControl();

    public items = input.required<DropdownButtonItem[]>();

    protected label = '';

    private destroy$ = new Subject<void>();

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    private onChange = (url: string): void => { };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onTouched = (): void => { };

    constructor(
        private controlDirective: NgControl
    ) {
        this.controlDirective.valueAccessor = this;

        effect(() => {
            this.updateLabel();
        });
    }

    private updateLabel(): void {
        this.label = this.items().find(item => item.value === this.control.value)?.label || '';
    }

    public ngOnInit(): void {
        this.control = this.controlDirective.control as FormControl;

        this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateLabel());

        this.updateLabel();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(val: string): void {
        if (!this.control.value) {
            this.control.setValue(val);
        }
    }

    protected setValue(value: string | number): void {
        this.control.patchValue(value);
    }
}
