import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public error(label: string, ...args: any[]): void {
        console.error(`[${label}]`, ...args);
    }
}
