import { Component, Input, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PartialVideoAd, Ratio } from '@shared/interactions/ad-preview/ad-preview.interface';
import { Window } from '../../../../typings/window';
import { BehaviorSubject, combineLatest, debounceTime, Subscription, tap } from 'rxjs';

interface PreviewConfigType<T, P> { type: T; params: P; }
export type PreviewConfig = PreviewConfigType<'1746213/Video-VAST-Outstream', PartialVideoAd>;

@Component({
    selector: 'ad-preview',
    templateUrl: './ad-preview.component.html',
    styleUrls: ['./ad-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdPreviewComponent implements AfterViewInit, OnDestroy {
    @Input('config')
    public set setPreviewConfig(previewConfig: PreviewConfig) {
        this.configPreview = previewConfig;
        this.configPreview$.next(previewConfig);
    }

    protected uniqueId = 'preview';

    private configPreview!: PreviewConfig;

    private configPreview$ = (new BehaviorSubject<PreviewConfig>(this.configPreview));

    private rerender$ = new BehaviorSubject<void>(void 0);

    private subscription?: Subscription;

    private isRendered = false;

    private currentVideoUrl = '';

    private currentVideoRatio: Ratio = 'rectangle';

    protected isAdblockDetected = false;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        this.uniqueId = `preview-${Math.floor(Math.random() * 1000000)}`;
    }

    private renderPreview(elementId: string, previewConfig: PreviewConfigType<string, PartialVideoAd>): void {
        if (this.isAdblockDetected) {
            return;
        }

        const parent = document.getElementById(elementId);

        if (!parent) {
            return;
        }

        this.isRendered = true;
        this.currentVideoUrl = previewConfig.params.videoUrl;
        this.currentVideoRatio = previewConfig.params.videoRatio || '16:9';

        const loaderHTML = document.createElement('div');
        loaderHTML.innerHTML = '<app-loader/>';
        parent?.appendChild(loaderHTML);

        (window as unknown as Window)?.dlApi?.cmd.push((dlApi) => {
            dlApi.previewAd(
                elementId,
                previewConfig.type,
                {
                    ...previewConfig.params,
                    previewId: this.uniqueId,
                    loop: true,
                    clickUrl: previewConfig.params.clickUrl ? this.prepareUrl(previewConfig.params.clickUrl) : undefined
                }
            );
        });
    }

    private updatePreview(previewConfig: Partial<PartialVideoAd>): void {
        window.postMessage({
            targets: [this.uniqueId],
            method: 'updatePreview',
            previewAdData: {
                ...previewConfig,
                previewId: this.uniqueId,
                clickUrl: previewConfig.clickUrl ? this.prepareUrl(previewConfig.clickUrl) : undefined
            }
        });
    }

    private prepareUrl(url: string): string {
        return /^https?:\/\//.test(url) ? url : `https://${url}`;
    }

    public ngAfterViewInit(): void {
        this.subscription = combineLatest([
            this.configPreview$,
            this.rerender$.pipe(
                tap(() => this.isRendered = false)
            )
        ])
            .pipe(debounceTime(1000))
            .subscribe(([config]) => {
                if (this.isRendered && this.currentVideoUrl === config.params.videoUrl && this.currentVideoRatio === config.params.videoRatio) {
                    this.updatePreview(config.params);
                } else {
                    this.renderPreview(this.uniqueId, config);
                }
            });

        this.detectAdblock();
    }

    private detectAdblock(): void {
        (window as unknown as Window)?.dlApi?.isAdblockDetected(detected => {
            this.isAdblockDetected = detected;
            this.changeDetectorRef.detectChanges();

            if (detected) {
                this.subscription?.unsubscribe();
            }
        });
    }

    public onWindowResize(): void {
        this.rerender$.next();
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
