import { OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { Injectable } from '@angular/core';

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
    /** A label for the cancel button */
    public override cancelBtnLabel = 'Anuluj';

    /** A label for the set button */
    public override setBtnLabel = 'Ustaw';
}
