import { Component } from '@angular/core';

@Component({
    selector: 'component-loading-bar',
    template: '<mat-spinner color="primary" diameter="20"></mat-spinner>',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

}
