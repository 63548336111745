import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AsyncJobResponse } from '../interfaces/async-job-response.interface';
import { AsyncJobsFetcherService } from './async-jobs-fetcher.service';

@Injectable()
export class AsyncJobsFetcherServiceClientApi extends AsyncJobsFetcherService {
    constructor(
        private readonly http: HttpClient
    ) {
        super();
    }

    public getJobResult<T>(job: string): Observable<AsyncJobResponse<T>> {
        return this.http.post<{ data: { job: AsyncJobResponse<T>; }; }>('/api/gateway', {
            query: `
                query JOB($job: String!){
                    job(id: $job) {
                        status
                        result
                    }
                }
            `,
            variables: { job }
        }).pipe(map(res => res.data.job));
    }
}
