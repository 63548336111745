import * as Sentry from '@sentry/angular';
import { Window } from './src/typings/window';

export const sentryInit = () => {
    if ((window as unknown as Window).env !== 'prod') {
        return;
    }

    Sentry.init({
        dsn: 'https://2d72e0f80889de6e0be10cfaf3f86034@o327436.ingest.us.sentry.io/4507338383425536',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            Sentry.httpClientIntegration()
        ],
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        initialScope: scope => {
            scope.setTags({
                vhost: window.location.host,
                websiteId: localStorage.storedSelectedWebsiteId,
                contractorId: localStorage.storedSelectedContractorId
            });

            return scope;
        }
    });
};
