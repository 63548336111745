import { Injectable } from '@angular/core';
import { delay, lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { AsyncJobsFetcherService } from './connectors/async-jobs-fetcher.service';
import { AsyncJobRequest } from './interfaces/async-job-request.interface';
import { AsyncJobResponse } from './interfaces/async-job-response.interface';

@Injectable()
export class AsyncJobsService {
    constructor(
        private readonly fetcher: AsyncJobsFetcherService
    ) {

    }

    public fetch<T>(request: Observable<AsyncJobRequest>, options?: { interval?: number; }): Promise<AsyncJobResponse<T>> {
        const getResult = <T>(job: string): Observable<AsyncJobResponse<T>> => {
            return of(job).pipe(
                delay(options?.interval ?? 1000),
                switchMap(job => this.fetcher.getJobResult<T>(job)),
                switchMap(res => {
                    if (res.status === 'queued' || res.status === 'processing') {
                        return getResult<T>(job);
                    } else {
                        return of(res as AsyncJobResponse<T>);
                    }
                })
            );
        };

        return lastValueFrom(
            request.pipe(switchMap(res => {
                if (res.status && res.jobId) {
                    return getResult<T>(res.jobId);
                }

                return of({ status: 'error', message: res.message } as AsyncJobResponse<T>);
            }))
        );
    }
}
