import { Memomize } from '@helpers/index';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { WebsiteAccessInterface } from '@core/website';
import { WebsiteContextService } from './website-context.service';
import { ContractorContextService } from './contractor-context.service';
import { ContractorAccessInterface } from '@core/contractors';
import { MePermissionInterface, MeService } from '@core/me';

@Injectable({
    providedIn: 'root'
})
export class PermissionContextService {
    constructor(
        private readonly websiteContext: WebsiteContextService,
        private readonly contractorContext: ContractorContextService,
        private readonly meService: MeService
    ) {}

    @Memomize()
    public getSelectedWebsitePermissions(): Observable<WebsiteAccessInterface> {
        return this.websiteContext.getSelectedWebsite(false).pipe(
            map(website => {
                if (website) {
                    return website.access;
                }

                return {
                    canEditWebsite: false,
                    canViewWebsite: false,
                    isWebsiteAnalyst: false,
                    isWebsiteOperator: false
                };
            })
        ).pipe(
            shareReplay(1)
        );
    }

    @Memomize()
    public getSelectedContractorPermissions(): Observable<ContractorAccessInterface> {
        return this.contractorContext.getSelectedContractor(false).pipe(
            map(contractor => {
                if (contractor) {
                    return contractor.access;
                }

                return {
                    isContractorOwner: false,
                    isContractorAdmin: false,
                    canEditAnyWebsite: false,
                    canEditContractor: false,
                    canViewContractor: false,
                    canViewPermissions: false,
                    canViewContractorFinances: false,
                    canViewWebsiteListPermissions: false,
                    canCreatePermissionForAdmin: false,
                    canCreatePermissionForOwner: false,
                    canCreatePermissionForWebsiteAnalyst: false,
                    canCreatePermissionForWebsiteOperator: false,
                    canDeletePermissionForAdmin: false,
                    canDeletePermissionForOwner: false,
                    canDeletePermissionForWebsiteAnalyst: false,
                    canDeletePermissionForWebsiteOperator: false
                };
            })
        ).pipe(
            shareReplay(1)
        );
    }

    @Memomize()
    public getMePermissions(): Observable<MePermissionInterface> {
        return this.meService.getPermissions().pipe(
            shareReplay(1)
        );
    }
}
