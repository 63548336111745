import {
    Component,
    ElementRef, EventEmitter,
    input,
    OnInit, Output,
    ViewChild
} from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { defer } from 'lodash';

export type wrapBelow = 'md' | 'lg';

export interface RadioBoxButtonComponentInterface {
    value: string | number;
    unit?: string;
    format?: string;
    name?: string;
    description?: string;
    label?: string;
    labelTextColor?: string;
    labelBackgroundColor?: string;
    inputLabel?: string;
    type: 'fixed' | 'custom';
    wrapBelow?: wrapBelow;
}

@Component({
    selector: 'radio-box-button',
    templateUrl: './radio-box-button.component.html',
    styleUrl: './radio-box-button.component.scss'
})
export class RadioBoxButtonComponent implements ControlValueAccessor, OnInit {
    protected control: FormControl = new FormControl();

    public value = input.required<string | number>();

    public wrapBelow = input<wrapBelow>('md');

    public checked = input<boolean | null>(null);

    public unit = input<string>();

    public format = input<string>('1.1');

    public name = input<string>();

    public description = input<string>();

    public label = input<string>();

    public labelTextColor = input<string | undefined>('#fff');

    public labelBackgroundColor = input<string | undefined>('#117e63');

    public type = input.required<'fixed' | 'custom'>();

    public inputLabel = input<string>('');

    @Output()
    public onItemClick = new EventEmitter<'fixed' | 'custom'>();

    @ViewChild('radioButton', { static: false })
    protected matRadioButton!: MatRadioButton;

    @ViewChild('inputElement')
    protected inputElement!: ElementRef<HTMLInputElement>;

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    private onChange = (url: string): void => { };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onTouched = (): void => { };

    constructor(
        private controlDirective: NgControl
    ) {
        this.controlDirective.valueAccessor = this;
    }

    public ngOnInit(): void {
        this.control = this.controlDirective.control as FormControl;

        if (this.control.value === this.value() || this.checked()) {
            defer(() => {
                this.matRadioButton.checked = this.checked() !== false;
            });
        }
    }

    protected handleOnClick(): void {
        if (this.matRadioButton.checked) {
            return;
        }

        this.control.setValue(this.value());

        if (this.type() === 'custom') {
            defer(() => {
                this.inputElement.nativeElement.focus();
            });
        }

        this.matRadioButton.checked = true;
        this.onItemClick.emit(this.type());
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(val: string): void {
        if (!this.control.value) {
            this.control.setValue(val);
        }
    }
}
