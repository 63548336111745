import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    catchError,
    distinctUntilChanged,
    interval,
    lastValueFrom,
    map,
    Observable,
    of,
    ReplaySubject,
    share,
    startWith,
    Subject,
    switchMap,
    takeUntil,
    tap
} from 'rxjs';
import { SessionInterface } from './interfaces/session.interface';
import { LoggerService } from '@core/logger/logger.service';

@Injectable({
    providedIn: 'root'
})
export abstract class AuthenticationService {
    private user$: Observable<SessionInterface['user']> | null = null;

    private session$: Observable<SessionInterface> | null = null;

    private cachedSession: SessionInterface | null = null;

    private refresh$ = interval(30000).pipe(startWith(0));

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly logger: LoggerService
    ) { }

    public session(): Observable<SessionInterface> {
        if (this.session$ === null) {
            this.session$ = new Observable<SessionInterface>(subscriber => {
                const active = new Subject();

                this.refresh$
                    .pipe(
                        takeUntil(active),
                        switchMap(() => this.getMe()),
                        switchMap((res) => {
                            // If user exists we just return it (as Observable... switchMap must return Observable)
                            if (res.user) {
                                return of(res);
                            } else {
                                // In other case we try to refresh session...
                                // To do this we run authenticate (it try to refresh session in background) and return status
                                // If status is true we again try to get user from api otherwise we'll return null as user
                                return of(null).pipe(
                                    switchMap(() => this.authenticate()),
                                    switchMap((state) => {
                                        if (state) {
                                            return this.getMe();
                                        }

                                        return of({ user: null, token: null });
                                    })
                                );
                            }
                        })
                    ).pipe(
                        tap(res => {
                            localStorage.setItem('userInfo', JSON.stringify(res.user));
                            this.cachedSession = res;
                        })
                    ).subscribe(res => {
                        subscriber.next(res);
                    });

                return () => {
                    active.next(false);
                    active.complete();
                };
            }).pipe(share({
                connector: () => new ReplaySubject(1), resetOnError: true, resetOnComplete: true, resetOnRefCountZero: true
            }));
        }

        return this.session$;
    }

    public user(): Observable<SessionInterface['user']> {
        if (this.user$ === null) {
            this.user$ = this.session().pipe(map(res => res.user), distinctUntilChanged((previous, current) => previous?.email === current?.email));
        }

        return this.user$;
    }

    public logout(onDemand = false): void {
        lastValueFrom(this.http.get('/api/logout')).then(() => {
            if (onDemand) {
                window.location.assign('/');
            } else {
                window.location.reload();
            }
        });
    }

    private authenticate(): Promise<boolean> {
        return new Promise(res => {
            // To refresh session we use iframe. We can not run ajax request becasue of cors
            let iframe: HTMLIFrameElement | null = document.createElement('iframe');

            // This is for firefox browser that prevent to load iframe from different domain (konto.onet.pl)
            // and don't dispatch onerror on iframe.
            const timeout = setTimeout(() => {
                try {
                    // We try to get href property if iframe stay in the same domain nothing happen and we resolve promise
                    // but if iframe 'll redirect to konto.onet.pl (that means we are not logged)
                    // then trying get href property throw exception do to security reason and in this case we reject promise.
                    iframe?.contentWindow?.location.href;

                    res(true);
                } catch {
                    res(false);
                }

                if (iframe) {
                    iframe.parentElement?.removeChild(iframe);
                    iframe = null;
                }
            }, 600);

            iframe.onload = function(): void {
                if (iframe) {
                    try {
                        // If authentication success iframe redirect to our host
                        // We put it in try/cache because if session'll expire and iframe redirect to loggin page (other domain)
                        // then access to iframe.contentWindow.location will throw permission exception
                        if (iframe.contentWindow?.location.host === window.location.host) {
                            res(true);
                        } else {
                            res(false);
                        }
                    } catch {
                        res(false);
                    }

                    // To awoid memory leaks we remove iframe and clear reference
                    iframe.parentElement?.removeChild(iframe);
                    iframe = null;
                    // In this place we can clear timeout becouse we don't need it anymore
                    clearTimeout(timeout);
                }
            };

            iframe.onerror = function(): void {
                res(false);
                // In this place we can clear timeout becouse we don't need it anymore
                clearTimeout(timeout);
            };

            iframe.src = '/user-session-proxy/authenticate';

            document.body.append(iframe);
        });
    }

    private getMe(): Observable<SessionInterface> {
        return this.http.get<SessionInterface>('/api/me').pipe(
            catchError(err => {
                this.logger.error('CONNECTION_ERROR', err);

                return of(this.cachedSession ?? { user: null, token: null });
            })
        );
    }
}
