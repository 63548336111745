import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';

export interface CopyDialogParams {
    textToCopy: string;
    title?: string;
    buttonLabel?: string;
    width?: number;
}
@Component({
    selector: 'app-copy-dialog',
    templateUrl: './copy-dialog.component.html',
    styleUrls: ['./copy-dialog.component.scss']
})
export class CopyDialogComponent {
    @ViewChild('tooltip') public tooltip!: MatTooltip;

    public title: string | undefined;

    public buttonLabel: string;

    public textToCopy: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CopyDialogParams,
        private clipboard: Clipboard
    ) {
        this.title = data.title ?? 'Skopiuj do schowka';
        this.textToCopy = data.textToCopy;
        this.buttonLabel = data.buttonLabel ?? 'Kopiuj';
    }
}
