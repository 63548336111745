import { Component, ComponentRef, Inject, TemplateRef, ViewChild, ViewContainerRef, Type } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LazyLoaderService } from '@core/lazy';

export interface FullScreenDialogParams {
    component?: Type<unknown>;
    inputs?: Record<string, unknown>;
}

@Component({
    selector: 'app-full-screen-dialog',
    templateUrl: './full-screen-dialog.component.html',
    styleUrls: ['./full-screen-dialog.component.scss']
})
export class FullScreenDialogComponent {
    @ViewChild('target', { read: ViewContainerRef, static: false })
    private viewContainerRef!: ViewContainerRef;

    @ViewChild('loader', { read: TemplateRef, static: true })
    private loaderTemplateRef!: TemplateRef<unknown>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public componentRef!: ComponentRef<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: FullScreenDialogParams,
        public dialogRef: MatDialogRef<FullScreenDialogComponent>,
        private readonly lazyLoader: LazyLoaderService
    ) {
    }

    public ngAfterViewInit(): void {
        this.viewContainerRef.createEmbeddedView(this.loaderTemplateRef);

        if (this.data.component) {
            this.viewContainerRef.clear();
            this.componentRef = this.viewContainerRef.createComponent(this.data.component);

            if (this.data.inputs) {
                Object.entries(this.data.inputs).forEach(([key, value]) => {
                    this.componentRef.setInput(key, value);
                });
            }
        } else {
            throw new Error('You HAVE TO provide module or component param');
        }

        this.componentRef.changeDetectorRef.detectChanges();
    }
}
