import { Injectable } from '@angular/core';
import { GraphqlService } from '@core/graphql/graphql.service';
import { Endpoint } from '@core/graphql/models/enums/endpoint.enum';
import { GraphqlMutationResponse } from '@core/graphql/models/graphql-mutation-response.model';
import { GraphqlPaginatedResponse } from '@core/graphql/models/graphql-paginated-response.model';
import { Observable, filter, first, interval, lastValueFrom, map, of, switchMap, timeout } from 'rxjs';
import { ServiceCreateInterface, ServiceInterface, ServiceStatus, ServiceType } from './models/service.interface';

import servicesGqlModel from './graphql/models/service.model.graphql';
import createServiceGqlMutation from './graphql/mutations/create-service.mutation.graphql';
import resultModelGql from '@core/graphql/query-models/result.model.graphql';
import getServiceGqlQuery from './graphql/queries/get-service.query.graphql';
import getServicesGqlQuery from './graphql/queries/get-services.query.graphql';

export interface ServiceIdentity {
    contractorId: number;
    websiteId: number;
    type: ServiceType;
}

@Injectable({
    providedIn: 'root'
})
export class ServiceService {
    constructor(
        private readonly graphqlService: GraphqlService
    ) {

    }

    public getServices(contractorId: number, websiteId: number): Observable<ServiceInterface[]> {
        const query = getServicesGqlQuery + servicesGqlModel;

        return this.graphqlService.query<{ contractor: { website: { services: GraphqlPaginatedResponse<ServiceInterface>; }; }; }>(
            Endpoint.GatewayApi,
            query,
            { contractorId, websiteId }
        ).pipe(
            map(res => res.contractor.website.services.items)
        );
    }

    public getService<T = never>(contractorId: number, websiteId: number, type: ServiceType): Observable<ServiceInterface<T> | null> {
        const query = getServiceGqlQuery + servicesGqlModel;

        return this.graphqlService.query<{ contractor: { website: { service: ServiceInterface<T>; }; }; }>(
            Endpoint.GatewayApi,
            query,
            { contractorId, websiteId, type }
        ).pipe(
            map(res => res.contractor?.website?.service ?? null)
        );
    }

    public createService<T>(service: ServiceCreateInterface<T>): Promise<boolean> {
        const query = createServiceGqlMutation + resultModelGql;

        return lastValueFrom(this.graphqlService.mutation<{ contractor: { website: { service: { create: GraphqlMutationResponse; }; }; }; },
            ServiceCreateInterface<T>>(
                Endpoint.GatewayApi,
                query,
                service
            ).pipe(
                map(res => res.contractor.website.service.create.status)
            )
        );
    }

    public ensureServiceStatus(
        service: ServiceIdentity,
        statuses: ServiceStatus[],
        options?: {
            timeout?: number;
            interval?: number;
        }
    ): Promise<boolean> {
        // First we create interval event with specific interval value
        const query = interval(options?.interval ?? 1000).pipe(
            // Then each tick we switch to request for service with specific params
            switchMap(() => this.getService(service.contractorId, service.websiteId, service.type)),
            // Then we block stream if service status is different than requested
            filter(service => statuses.includes(service?.status as ServiceStatus)),
            // In this plce we receive service with required staus
            map(() => true),
            // There we configure timeout. If none value came there in specisic timeout pipe'll emit false
            timeout({ with: () => of(false), first: options?.timeout ?? 10000 }),
            // And at the end we take first value. there we always receive true or false
            first()
        );

        return lastValueFrom(query);
    }
}
