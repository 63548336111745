<div class="header">
    <h4
        *ngIf="title"
        mat-dialog-title
    >{{title}}</h4>
    <button
        class="close-button"
        mat-icon-button
        mat-dialog-close
    >
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <copy-input
        [value]="textToCopy"
        [buttonLabel]="buttonLabel"
    ></copy-input>
</div>
