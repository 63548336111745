import { ContractorInterface } from '@ecommerce/lib-interfaces';
import { WebsiteInterface } from '../../website/models/website.model';
import { GraphqlPaginatedResponse } from '@core/graphql/models/graphql-paginated-response.model';

export enum FeedStatus {
    CREATED = 'CREATED',
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    VERIFYING = 'VERIFYING'
}

export interface FeedReportInterface {
    offersValid: number;
    offersInvalid: number;
}

export interface FeedInterface {
    id: number;
    url: string;
    name: string;
    status: FeedStatus;
    scheduleTime: string[];
    services: string[];
    reports?: GraphqlPaginatedResponse<FeedReportInterface>;
}

type FeedActionInterface<T> = T & {
    websiteId: WebsiteInterface['id'];
    contractorId: ContractorInterface['id'];
};

export type FeedEditableInterface = Pick<FeedInterface, 'name' | 'url' | 'scheduleTime'>;

export type FeedEditableUpdateInterface = Pick<FeedInterface, 'id'> & Partial<FeedEditableInterface>;

export type FeedCreateInterface = FeedActionInterface<FeedEditableInterface>;

export type FeedRemoveInterface = FeedActionInterface<Pick<FeedInterface, 'id'>>;

export type FeedUpdateInterface = FeedActionInterface<FeedEditableUpdateInterface>;

// export type FeedEntityInterface = FeedInterface & {
//     reports?: GraphqlPaginatedResponse<FeedReportInterface>;
// };
