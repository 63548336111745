import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {
    @Input()
    public value!: boolean;

    @Input()
    public label?: string;

    @Input()
    public color: 'accent' | 'primary' | 'error' | 'success' | 'on-off' = 'accent';

    @Input()
    public disabled = false;

    @Output()
    public onValueChange = new EventEmitter();

    @Input()
    public tooltipDescription = '';

    protected onToggle(): void {
        this.onValueChange.emit(!this.value);
        this.value = !this.value;
    }
}
