import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AdPreviewComponent } from '@shared/interactions/ad-preview/ad-preview.component';
import { NoDataChartComponent } from '@shared/interactions/no-data-chart/no-data-chart.component';
import { ObjectActionsComponent } from '@shared/interactions/object-actions/object-actions.component';
import { FormControlsModule } from '../form-controls/form-controls.module';
import { UiModule } from '../ui/ui.module';
import { ClickStopPropagationDirective } from './click-stop-propagation/click-stop-propagation.directive';
import { CopyInputComponent } from './copy-input/copy-input.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { FullScreenDialogComponent } from './dialog/full-screen-dialog/full-screen-dialog.component';
import { DialogService } from './dialog/dialog.service';
import { InfoDialogComponent } from './dialog/info-dialog/info-dialog.component';
import { SelectDialogComponent } from './dialog/select-dialog/select-dialog.component';
import { CopyDialogComponent } from './dialog/copy-dialog/copy-dialog.component';
import { CanViewGuardDirective } from './directives/can-view-guard';
import { CopyableComponent } from './directives/copyable/copyable.component';
import { CopyableDirective } from './directives/copyable/copyable.directive';
import { ErrorComponent } from './error/error.component';
import { FileInputComponent } from './file-input/file-input.component';
import { FilterPipe } from './filter/filter.pipe';
import { HelpTooltipComponent } from './help-tooltip/help-tooltip.component';
import { HelpTooltipDirective } from './help-tooltip/help-tooltip.directive';
import { AutofocusDirective } from './input-autofocus/input-autofocus.directive';
import { LoaderComponent } from './loader/loader.component';
import { MenuPanelActionComponent } from './menu-panel/menu-panel-action/menu-panel-action.component';
import { MenuPanelContentComponent } from './menu-panel/menu-panel-content/menu-panel-content.component';
import { MenuPanelHeaderComponent } from './menu-panel/menu-panel-header/menu-panel-header.component';
import { MenuPanelComponent } from './menu-panel/menu-panel/menu-panel.component';
import { LoadingComponent } from './pending/loading.component';
import { PendingDirective } from './pending/pending.directive';
import { RightPanelContentComponent } from './right-panel/components/right-panel-content';
import { RightPanelFooterComponent } from './right-panel/components/right-panel-footer';
import { RightPanelComponent } from './right-panel/right-panel.component';
import { RightPanelService } from './right-panel/right-panel.service';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { SnackBarService } from './snack-bar/snack-bar.service';
import { SwitchComponent } from './switch/switch.component';
import { TermsComponent } from './terms/terms.component';
import { VideoImageUploaderComponent } from './video-image-uploader/video-image-uploader.component';
import { PeriodPickerComponent } from '@shared/interactions/period-picker/period-picker.component';
import {
    PeriodPickerCalendarHeaderComponent
} from '@shared/interactions/period-picker/components/period-picker-calendar-header/period-picker-calendar-header.component';
import {
    PeriodPickerPresetsComponent
} from '@shared/interactions/period-picker/components/period-picker-presets/period-picker-presets.component';
import { RadioBoxButtonComponent } from '@shared/interactions/radio-box-button/radio-box-button.component';
import { MatRadioModule } from '@angular/material/radio';
import { RadioBoxGroupComponent } from '@shared/interactions/radio-box-group/radio-box-group.component';
import { DropdownButtonComponent } from '@shared/interactions/dropdown-button/dropdown-button.component';

const exported = [
    PendingDirective,
    HelpTooltipDirective,
    FileInputComponent,
    ClickStopPropagationDirective,
    AutofocusDirective,
    FilterPipe,
    LoaderComponent,
    MenuPanelComponent,
    MenuPanelHeaderComponent,
    MenuPanelContentComponent,
    MenuPanelActionComponent,
    ErrorComponent,
    CopyInputComponent,
    ErrorComponent,
    CopyableDirective,
    SwitchComponent,
    ObjectActionsComponent,
    CanViewGuardDirective,
    NoDataChartComponent,
    RightPanelFooterComponent,
    RightPanelContentComponent,
    AdPreviewComponent,
    TermsComponent,
    VideoImageUploaderComponent,
    PeriodPickerComponent,
    RadioBoxButtonComponent,
    RadioBoxGroupComponent,
    DropdownButtonComponent
];

@NgModule({
    declarations: [
        LoadingComponent,
        ConfirmDialogComponent,
        InfoDialogComponent,
        SelectDialogComponent,
        CopyDialogComponent,
        HelpTooltipComponent,
        FullScreenDialogComponent,
        ...exported,
        SnackBarComponent,
        RightPanelComponent,
        RightPanelFooterComponent,
        RightPanelContentComponent,
        CopyableComponent,
        PeriodPickerCalendarHeaderComponent,
        PeriodPickerPresetsComponent
    ],
    imports: [
        CommonModule,
        UiModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        MatTooltipModule,
        FormControlsModule,
        MatProgressSpinnerModule,
        RouterModule,
        MatStepperModule,
        ClipboardModule,
        FormsModule,
        MatMenuModule
    ],
    exports: [...exported, MatTooltipModule, MatStepperModule, MatMenuModule],
    providers: [DialogService, SnackBarService, RightPanelService]
})
export class InteractionsModule {}
