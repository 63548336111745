import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { InputErrorsComponent } from './input-errors/input-errors.component';
import { Platform } from '@angular/cdk/platform';
import { CustomDateAdapter } from '@shared/form-controls/adapters/custom-date.adapter';
import { InputDatetimeComponent } from './input-datetime/input-datetime.component';
import {
    OWL_DATE_TIME_LOCALE, OwlDateTimeIntl,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
} from '@danielmoncada/angular-datetime-picker';
import { DefaultIntl } from '@shared/form-controls/input-datetime/input-datetime.intl';

const exportedModules = [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
];

const components = [
    InputErrorsComponent,
    InputDatetimeComponent
];

@NgModule({
    declarations: [components],
    imports: [
        ...exportedModules
    ],
    exports: [
        ...exportedModules,
        ...components
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
            deps: [MAT_DATE_LOCALE, Platform]
        },
        {
            provide: OWL_DATE_TIME_LOCALE,
            useValue: 'pl'
        },
        { provide: OwlDateTimeIntl, useClass: DefaultIntl }
    ]
})
export class FormControlsModule { }
