import { Component, Input } from '@angular/core';
import { SnackBarService } from '@shared/interactions/snack-bar/snack-bar.service';

@Component({
    selector: 'copy-input',
    templateUrl: './copy-input.component.html',
    styleUrls: ['./copy-input.component.scss']
})
export class CopyInputComponent {
    @Input()
    public value = '';

    @Input()
    public buttonLabel = 'kopiuj kod';

    constructor(private readonly snackbarService: SnackBarService) {
    }

    public copy(): void {
        this.snackbarService.success('Skopiowano do schowka!', { duration: 2000 });
    }
}
