<div class="actions-wrapper">
    <div class="ea-buttons">
        <button mat-icon-button class="action" *ngFor="let action of available" [matTooltip]="action.label" (click)="action?.fn()">
            <mat-icon class="material-symbols-outlined">
                {{ action.icon }}
            </mat-icon>
        </button>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="hiddenActions.length" matTooltip="Więcej">
        <mat-icon class="material-icons material-icons-outlined">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item class="action" *ngFor="let action of hiddenActions" [title]="action.label"
                (click)="action?.fn()">
            <mat-icon class="material-symbols-outlined">
                {{ action.icon }}
            </mat-icon>
            {{ action.label }}
        </button>
    </mat-menu>
</div>

