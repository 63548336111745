import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { WebsiteContextService } from '@core/context/website-context.service';
import { Observable, map, of, tap, catchError } from 'rxjs';
import { WebsiteService } from '@core/website';
import { ContractorContextService } from '@core/context';
import { ContractorService } from '@core/contractors';
import { SnackBarService } from '@shared/interactions/snack-bar/snack-bar.service';

@Injectable({
    providedIn: 'root'
})
export class HasForcedContextGuard {
    constructor(
        private readonly websiteContextService: WebsiteContextService,
        private readonly contractorContextService: ContractorContextService,
        private readonly websiteService: WebsiteService,
        private readonly contractorService: ContractorService,
        private readonly snackbarService: SnackBarService,
        private readonly router: Router
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (route.queryParams.websiteId) {
            const websiteId = Number(route.queryParams.websiteId);
            const url = state.url.split('?')[0];

            return this.websiteService.getWebsiteById(websiteId).pipe(
                map(website => {
                    if (website) {
                        this.contractorContextService.changeContractor(website.contractorId);
                        this.websiteContextService.changeWebsite(website.id);
                    } else {
                        this.snackbarService.alert('Nie udało się zalogować do wybranej witryny.');
                    }

                    return true;
                }),
                catchError(() => {
                    this.snackbarService.alert('Nie udało się zalogować do wybranej witryny.');

                    return of(true);
                }),
                tap(() => this.router.navigate([url]))
            );
        }

        if (route.queryParams.contractorId) {
            const contractorId = Number(route.queryParams.contractorId);
            const url = state.url.split('?')[0];

            return this.contractorService.getContractor(contractorId).pipe(
                map(contractor => {
                    if (contractor) {
                        this.contractorContextService.changeContractor(contractor.id);
                    } else {
                        this.snackbarService.alert('Nie udało się zalogować do wybranej firmy.');
                    }

                    return true;
                }),
                catchError(() => {
                    this.snackbarService.alert('Nie udało się zalogować do wybranej firmy.');

                    return of(true);
                }),
                tap(() => this.router.navigate([url]))
            );
        }

        return of(true);
    }
}
