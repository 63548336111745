import { Directive, ElementRef, Input, ViewContainerRef } from '@angular/core';
import { HelpTooltipComponent } from './help-tooltip.component';

@Directive({
    selector: '[helpTooltip]'
})
export class HelpTooltipDirective {
    @Input('helpTooltip')
    public set pending(value: string | undefined) {
        if (value) {
            this.renderHelp(value);
        }
    }

    constructor(private element: ElementRef,
        private viewContainerRef: ViewContainerRef
    ) { }

    private renderHelp(text: string): void {
        if (!text) {
            return;
        }

        const componentRef = this.viewContainerRef.createComponent(HelpTooltipComponent);
        componentRef.instance.tooltipContent = text;
        const host = this.element.nativeElement;
        host.appendChild(componentRef.location.nativeElement);
    }
}
