/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthenticationService } from '@core/authentication';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class IsAuthorizedGuard {
    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly router: Router
    ) { }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authenticationService.user().pipe(
            map(user => user !== null
                ? true
                : this.router.createUrlTree(
                    ['login'],
                    {
                        queryParams: {
                            redirect_uri: state.url
                        }
                    }
                )
            )
        );
    }

    public canLoad(
        route: Route,
        segments: UrlSegment[]
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.authenticationService.user().pipe(map(user => user !== null ? true : this.router.createUrlTree(['login'])));
    }
}
