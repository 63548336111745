import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public transform(items: any[], filterKey: string, searchText: string): any[] {
        if (!items) {
            return [];
        }

        if (!searchText) {
            return items;
        }

        searchText = searchText.toLocaleLowerCase();

        return items.filter(it => {
            return it[filterKey].toLocaleLowerCase().includes(searchText);
        });
    }
}
