import { Component, Input } from '@angular/core';

@Component({
    selector: 'attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent {
    @Input()
    public href!: string | null;
}
