<div class="header">
    <h4
        *ngIf="title"
        mat-dialog-title
    >{{title}}</h4>
    <button
        class="close-button"
        mat-icon-button
        mat-dialog-close
    >
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <div
        class="message"
        [innerHTML]="message"
    ></div>
</div>
<div
    class="action-buttons"
    mat-dialog-actions
>
    <button
        mat-raised-button
        color="primary"
        mat-dialog-close
    > {{buttonOk}} </button>
</div>