import { Observable, Subject, combineLatest, distinctUntilChanged, map, takeUntil } from 'rxjs';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive, InjectionToken,
    Injector,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';

export interface CanActivate {
    canActivate: () => Observable<boolean>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CanActivateClassGuard = new(...args: any[]) => CanActivate;

export type CanActivateFnGuard = () => boolean | Promise<boolean> | Observable<boolean>;

export type CanActivateGuard = CanActivateClassGuard | CanActivateFnGuard | InjectionToken<CanActivate>;

// Function detect if argument is CanActivateClassGuard type or not
function isClassGuard(guard: CanActivateGuard): guard is CanActivateClassGuard {
    return ((guard as CanActivateClassGuard | CanActivateFnGuard).prototype
        && (guard as CanActivateClassGuard | CanActivateFnGuard).prototype.constructor === guard);
}

function isInjectionToken(guard: CanActivateClassGuard | CanActivateFnGuard | InjectionToken<CanActivate>): guard is InjectionToken<CanActivate> {
    return guard instanceof InjectionToken;
}

@Directive({
    selector: '[canView]'
})
export class CanViewGuardDirective implements AfterViewInit, OnDestroy {
    private destroy$ = new Subject();

    @Input()
    public canView?: CanActivateGuard[];

    constructor(
        private view: ViewContainerRef,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private template: TemplateRef<any>,
        private injector: Injector,
        private ref: ChangeDetectorRef
    ) { }

    public ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    public ngAfterViewInit(): void {
        if (this.canView?.length) {
            const checks = this.canView.map(guard => {
                if (isClassGuard(guard) || isInjectionToken(guard)) {
                    return this.injector.get<CanActivate>(guard).canActivate();
                } else {
                    const value = guard();

                    switch (true) {
                        case typeof value === 'boolean':
                            return Promise.resolve(value);
                        case value instanceof Promise:
                        case value instanceof Observable:
                            return value;
                        default:
                            throw new Error(`Function guard return unsupported value ${JSON.stringify(value)}`);
                    }
                }
            });

            combineLatest(checks).pipe(
                map(results => results.findIndex(item => item === false) === -1)
            ).pipe(
                // We need to prevent emitting the same value because it will cause duplicate content.
                distinctUntilChanged(),
                // And unsubscribe on component destruction.
                takeUntil(this.destroy$)
            ).subscribe((status) => {
                if (status) {
                    this.view.createEmbeddedView(this.template, this.canView);
                } else {
                    this.view.clear();
                }

                // Because component can use OnPush detection strategy we should run changes detection
                // to be sure thach changes will be applied
                this.ref.detectChanges();
            });
        } else {
            this.view.createEmbeddedView(this.template, this.canView);
        }
    }
}
