import { Component, Input } from '@angular/core';
import { SnackBarService } from '../../snack-bar/snack-bar.service';

@Component({
    selector: 'app-copyable',
    templateUrl: './copyable.component.html',
    styleUrls: ['./copyable.component.scss']
})
export class CopyableComponent {
    @Input()
    public content!: string;

    constructor(private readonly snackbarService: SnackBarService) {
    }

    public copy(): void {
        this.snackbarService.success('Skopiowano do schowka!', { duration: 1000 });
    }
}
