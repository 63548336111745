import { StatusType } from '../../../shared/ui/status/status.component';
import { ContractorInterface } from '@ecommerce/lib-interfaces';
import { WebsiteInterface } from '../../website/models/website.model';

export interface ServiceInterface<T = Record<string, unknown>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
    type: ServiceType;
    status: ServiceStatus;
    config: T;
}

export type ServiceCreateInterface<T = Record<string, unknown>> = Pick<ServiceInterface<T>, 'type' | 'config'>
    & { contractorId: ContractorInterface['id']; }
    & { websiteId: WebsiteInterface['id']; };

export enum ServiceType {
    SKAPIEC = 'SKAPIEC',
    FEED = 'FEED',
    PIXEL = 'PIXEL',
    CONTENT_COMMERCE = 'CONTENT_COMMERCE',
    RETAIL_MEDIA = 'RETAIL_MEDIA',
    RETAIL_MEDIA_PARTNER = 'RETAIL_MEDIA_PARTNER',
    LAMODA = 'LAMODA',
    OPINEO = 'OPINEO',
    VIDEO_ADS = 'VIDEO_ADS',
    PERFORMANCE_EASY = 'PERFORMANCE_EASY'
}

export enum ServiceStatus {
    CREATING = 'CREATING',
    PROCESSING = 'PROCESSING',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    NOT_CREATED = 'NOT_CREATED',
    VERIFYING = 'VERIFYING'
}

export interface ServiceFullStatus {
    status: ServiceStatus;
    info: string;
    color: StatusType;
}
