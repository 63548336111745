<a
    *ngIf="href; else attachmentLoading"
    [href]="href"
    target="_blank"
><mat-icon>description</mat-icon> <ng-container *ngTemplateOutlet="contentTpl"></ng-container> </a>
<ng-template #attachmentLoading>
    <div class="attachment-loading">
        <mat-icon matTooltip="Nie udało się załadować zawartości">warning</mat-icon> <ng-container
            *ngTemplateOutlet="contentTpl"
        ></ng-container>
    </div>
</ng-template>
<ng-template #contentTpl><ng-content></ng-content></ng-template>