import { AfterViewInit, Component, Injector } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject, mergeWith } from 'rxjs';

@Component({
    selector: '[matErrorMessages]',
    template: '{{ error }}'
})
export class InputErrorsComponent implements AfterViewInit {
    public error?: string;

    private inputRef!: MatFormFieldControl<MatInput>;

    private onTouched = new Subject();

    constructor(
        private _inj: Injector
    ) { }

    public ngAfterViewInit(): void {
        const container = this._inj.get(MatFormField);
        this.inputRef = container._control;
        setTimeout(() => this.updateErrors(this.inputRef?.ngControl?.status, this.inputRef?.ngControl?.errors), 1);

        // we must add onFocusOut event listener because when focus out statusChanged is not changing
        container._elementRef.nativeElement.addEventListener('focusout', () => this.onTouched.next(null));

        // sub to the control's status stream
        this.inputRef.ngControl?.statusChanges?.pipe(mergeWith(this.onTouched))
            .subscribe((state) => this.updateErrors(state, this.inputRef?.ngControl?.errors));
    }

    private updateErrors(state: string | null | undefined, errors: ValidationErrors | null | undefined): void {
        if (!state || state === 'INVALID') {
            // active errors on the FormControl
            const controlErrors = errors;

            // just grab one error
            if (controlErrors) {
                const firstError = Object.keys(controlErrors)[0];
                const varList = Object.values(controlErrors)[0]; // variables of error

                const message = this.getMessage(firstError, varList);

                if (message) {
                    this.error = message;
                }
            }
        }

        return void 0;
    }

    private getMessage(firstError: string, varList: Record<string, unknown>): string {
        switch (firstError) {
            case 'required':
                return 'Pole wymagane';
            case 'email':
                return 'Błędny adres email';
            case 'weakPassword':
                return 'Hasło musi zawierać minimum 6 znaków, w tym jedną dużą literę, jedną małą oraz jedną cyfrę';
            case 'minlength':
                return `Minimalna ilość znaków ${varList.requiredLength}`;
            case 'maxlength':
                return `Maksymalna ilość znaków ${varList.requiredLength}`;
            case 'invalidNip':
                return 'Nieprawidłowy numer NIP';
            case 'invalidNipAlreadyExists':
                return 'Podany NIP już istnieje';
            case 'invalidNipOnlyDigitsAllowed':
                return 'NIP może zawierać tylko cyfry';
            case 'invalidRegon':
                return 'Nieprawidłowy numer REGON';
            case 'invalidUrl':
                return 'Błędny adres URL';
            case 'insecureUrl':
                return 'Adres URL musi zaczynać się od https://';
            case 'fileOverSize':
                return `Plik zbyt duży. Maksymalny rozmiar to ${varList.max}Mb`;
            case 'imageOverSizeX':
                return `Zdjęcie za szerokie. Maksymalny rozmiar to ${varList.max}px`;
            case 'imageUnderSizeX':
                return `Zdjęcie zbyt wąskie. Minimalny rozmiar to ${varList.min}px`;
            case 'imageOverSizeY':
                return `Zdjęcie za wysokie. Maksymalny rozmiar to ${varList.max}px`;
            case 'imageUnderSizeY':
                return `Zdjęcie za niskie. Minimalny rozmiar to ${varList.min}px`;
            case 'amountToLow':
                return `Minimalna kwota: ${varList.min} zł`;
            case 'amountToHigh':
                return `Maksymalna kwota: ${varList.max} zł`;
            case 'min':
                return `Minimalna wartość to ${varList.min}`;
            case 'max':
                return `Maksymalna wartość to ${varList.max}`;
            case 'amountLowerThanBudget':
                return `Kwota musi być wyższa niż wykorzystany budżet: ${varList.budget} zł`;
            case 'arrayMinNotEmpty':
                return `Wybierz co najmniej ${varList.requiredLength} opcję`;
            case 'matDatepickerParse':
                return 'Błędna data';
            case 'matDatepickerMin':
            case 'matDatetimePickerMin':
                return 'Musisz wybrać późniejszą datę';
            case 'dateToDaysBeforeDateFrom':
                return 'Data końcowa musi być późniejsza niż data początkowa';
            case 'dateToMinsBeforeDateFrom':
                return 'Godzina końcowa musi być późniejsza niż data początkowa';
            case 'resourceExists':
                switch (varList.field) {
                    case 'email':
                        return 'Podany adres email istnieje już w naszej bazie danych';
                    case 'regon':
                        return 'Podany Regon istnieje już w naszej bazie danych';
                    case 'nip':
                        return 'Podany NIP istnieje już w naszej bazie danych';
                    default:
                        return 'Podana wartość już istnieje w naszej bazie danych';
                }
            case 'onlyLetters':
                return 'Pole powinno zawierać tylko litery';
            case 'onlyHours':
                return 'Proszę wprowadzić poprawną wartość w formacie HH:00, z przedziału 00:00 - 23:00';
            case 'TwoDecimalPlacesValidator':
                return 'Maksymalnie dwa miejsca po przecinku';
            default:
                return 'Błędna wartość';
        }
    }
}
