import { Injectable } from '@angular/core';
import { PermissionInterface } from '@core/contractors';
import { ContractorContactInterface } from '@core/contractors/models/contractor-contact.model';
import { GraphqlMutationResponse } from '@core/graphql/models/graphql-mutation-response.model';
import { Memomize } from '@helpers/decorators';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';

import {
    ContractorService,
    CreateContractorContactInterface,
    CreateContractorInterface,
    CreatePermission,
    DeletePermission,
    GetContractorInterface,
    GetContractorsInterface,
    UpdateContractorContactInterface,
    UpdateContractorInterface
} from '../contractors/contractor.service';
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root'
})
export class ContractorContextService {
    private contractorsReload$ = new BehaviorSubject(0);

    private selectedContractorId$ = new BehaviorSubject(this.storedId);

    private selectedContractorReload$ = new BehaviorSubject(0);

    private selectedContractorLoadingStatus$ = new BehaviorSubject(true);

    private selectedContractorContactsReload$ = new BehaviorSubject<number>(0);

    private selectedContractorPermissionsReload$ = new BehaviorSubject<number>(0);

    public changeContractor(contractorId: number): void {
        this.selectedContractorId$.next(contractorId);
    }

    public async createContractor(contractor: CreateContractorInterface): Promise<GraphqlMutationResponse<{ id: number; }>> {
        const res = await this.contractorService.createContractor(contractor);
        const id = res.data?.id;

        if (res.status && id) {
            this.changeContractor(id);
            this.contractorsReload$.next(this.contractorsReload$.value + 1);
        }

        return res;
    }

    public async updateContractor(contractor: UpdateContractorInterface): Promise<GraphqlMutationResponse<never>> {
        const res = await this.contractorService.updateContractor(contractor);

        if (res.status) {
            // If selected contractor is the same that we change
            // we run can use changeContractor to refresh item
            if (this.storedId === contractor.id) {
                this.selectedContractorReload$.next(this.selectedContractorReload$.value + 1);
            }

            this.contractorsReload$.next(this.contractorsReload$.value + 1);
        }

        return res;
    }

    public createPermission(permission: CreatePermission): Promise<GraphqlMutationResponse<{ ids: number[]; }>> {
        return this.contractorService.createPermission(permission).then(res => {
            this.selectedContractorPermissionsReload$.next(0);

            return res;
        });
    }

    public deletePermission(permission: DeletePermission): Promise<GraphqlMutationResponse<never>> {
        return this.contractorService.deletePermission(permission).then(res => {
            this.selectedContractorPermissionsReload$.next(0);

            return res;
        });
    }

    public createContractorContact(contact: CreateContractorContactInterface): Promise<GraphqlMutationResponse<{ id: number; }>> {
        return this.contractorService.createContractorContact(contact).then(res => {
            this.selectedContractorContactsReload$.next(0);

            return res;
        });
    }

    public updateContractorContact(contact: UpdateContractorContactInterface): Promise<GraphqlMutationResponse<never>> {
        return this.contractorService.updateContractorContact(contact).then(res => {
            this.selectedContractorContactsReload$.next(0);

            return res;
        });
    }

    @Memomize()
    public getContractors(): Observable<GetContractorsInterface[]> {
        return this.contractorsReload$.pipe(
            switchMap(() => this.contractorService.getContractors(this.storedId)),
            shareReplay(1)
        );
    }

    public getSelectedContractor(): Observable<GetContractorInterface>;

    public getSelectedContractor(force: true): Observable<GetContractorInterface>;

    public getSelectedContractor(force: false): Observable<GetContractorInterface | null>;

    @Memomize()
    public getSelectedContractor(force?: boolean): Observable<GetContractorInterface | null> {
        if (force || force === void 0) {
            return this.getSelectedContractor(false).pipe(
                filter(contractor => contractor !== null)
            ).pipe(
                shareReplay(1)
            );
        }

        return this.selectedContractorId$.pipe(
            // Skip further processing if the id hasn't changed
            distinctUntilChanged(),

            // There we mark tak fetchin selected contractor is in progress
            tap(() => {
                this.selectedContractorLoadingStatus$.next(true);
            }),

            // Add the ability to reload the selected contractor
            switchMap(id => {
                return this.selectedContractorReload$.pipe(map(() => id));
            }),

            // Next, we try to fetch the contractor
            switchMap(id => {
                // If id exists we try to fetch contractor by id
                // In other case we fetch first available contractor
                if (id) {
                    return this.contractorService.getContractor(id).pipe(
                        switchMap(contractor => {
                            // If we fetch the contractor, we return it
                            // Otherwise, we try to find the first available contractor
                            return contractor ? of(contractor) : this.contractorService.getFirstContractor();
                        })
                    );
                } else {
                    return this.contractorService.getFirstContractor();
                }
            }),

            // Finally, we store the current contractor id and disable the loading status
            tap((contractor) => {
                // If the contractor exists, we store its id
                if (contractor?.id) {
                    this.storedId = contractor.id;
                }

                this.selectedContractorLoadingStatus$.next(false);
            }),

            // send selected contractor id and name to Sentry
            tap(contractor => Sentry.setTags({
                contractorId: contractor?.id || null,
                contractorName: contractor?.name || null
            }))
        ).pipe(
            shareReplay(1)
        );
    }

    @Memomize()
    public getSelectedContractorPermissions(): Observable<PermissionInterface[]> {
        return combineLatest([
            this.getSelectedContractor(),
            this.selectedContractorPermissionsReload$
        ]).pipe(
            switchMap(([contractor]) => this.contractorService.getContractorPermissions(contractor.id)),
            shareReplay(1)
        );
    }

    @Memomize()
    public getSelectedContractorContacts(): Observable<ContractorContactInterface[]> {
        return combineLatest([
            this.getSelectedContractor(),
            this.selectedContractorContactsReload$
        ]).pipe(
            switchMap(([contractor]) => contractor ? this.contractorService.getContractorContacts(contractor.id) : of([])),
            shareReplay(1)
        );
    }

    @Memomize()
    public getSelectedContractorLoadingStatus(): Observable<boolean> {
        return this.selectedContractorLoadingStatus$.pipe(distinctUntilChanged());
    }

    constructor(
        private readonly contractorService: ContractorService
    ) { }

    private get storedId(): number {
        return Number(localStorage.getItem('storedSelectedContractorId'));
    }

    private set storedId(id: number) {
        Number(localStorage.setItem('storedSelectedContractorId', String(id)));
    }
}
